import React, {useEffect, useState} from "react";
import {systemService, userServices} from "../../services";
import {Button, Col, Icon, notification, Row, Spin} from "antd";
import DividerBlock from "../divider-block";
import InputField from "../input-field";
import {ADVANCED_ACCOUNT_PROTECTION, COMPANY_DIRECTOR_PERMISSION} from "../../helpers/user_permission.helper";
import PhoneField from "../phone-field";

export default function UserProfileEditForm() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const [passwordFormData, setPasswordFormData] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
        code: '',
        codeToken: '',
    });

    const [loginFormData, setLoginFormData] = useState({
        oldLogin: '',
        oldLoginCountryID: null,
        oldLoginCode: '',
        oldLoginCodeToken: '',

        newLogin: '',
        newLoginCountryID: null,
        newLoginCode: '',
        newLoginCodeToken: '',
    });
    const [loadingLoginForm, setLoadingLoginForm] = useState({
        oldLoginCodeIsLoading: false,
        newLoginCodeIsLoading: false,
    });

    const [name, setName] = useState('');

    const [fieldsErrors, setFieldsErrors] = useState({});

    useEffect(() => {
        setUser(userServices.getUser());
    }, []);

    useEffect(() => {
        if (user) {
            setLoading(false);
            setName(user.info.fio);
            setLoginFormData({
                ...loginFormData,
                oldLogin: user.info.login,
                oldLoginCountryID: user.info.country.id,
            });
        }
    }, [user])

    const getFieldErrors = fieldName => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    const handleRequestCodOnChangePassword = () => {
        userServices.getVerifyCode()
            .then(resp => {
                setPasswordFormData({
                    ...passwordFormData,
                    codeToken: resp.token
                });
            })
            .catch(err => {
                notification.error({
                    message: "Код",
                    description: err,
                });
            });
    };

    const handleRequestCode = (phone, countryID, setToken) => {
        setLoadingLoginForm({
            ...loadingLoginForm,
            oldLoginCodeIsLoading: true,
            newLoginCodeIsLoading: true,
        });
        setFieldsErrors([]);
        systemService.getPhoneCode(phone, countryID)
            .then((resp) => {
                if (resp.status === 'error') {
                    setFieldsErrors(resp.errors);
                    notification.error({
                        message: "Код",
                        description: "Некорректные данные",
                    });
                } else {
                    notification.success({
                        message: "Отправка кода",
                        description: "Код отправлен на номер "+phone,
                    });
                    setToken(resp[0].token);
                }
            })
            .catch((err) => {
                notification.error({
                    message: "Отправка кода",
                    description: err,
                });
            })
            .finally(() => {
                setLoadingLoginForm({
                    ...loadingLoginForm,
                    oldLoginCodeIsLoading: false,
                    newLoginCodeIsLoading: false,
                });
            })
    };

    const handleChangePassword = () => {
        setLoading(true);
        userServices.changePassword(passwordFormData)
            .then(resp => {
                setFieldsErrors([]);
                if (resp.status === 'success') {
                    notification.success({
                        message: "Изменение пароля",
                        description: resp.message,
                    });

                    setPasswordFormData({
                        ...passwordFormData,
                        oldPassword: '',
                        newPassword: '',
                        newPasswordRepeat: '',
                        code: '',
                        codeToken: '',
                    })
                } else if (resp.status === 'error') {
                    setFieldsErrors(resp.errors);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Изменение пароля",
                    description: err,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleChangeName = () => {
        setLoading(true);
        userServices.changeName(name)
            .then(resp => {
                setFieldsErrors([]);
                if (resp.status === 'success') {
                    notification.success({
                        message: "Изменение имени",
                        description: resp.message,
                    });
                } else if (resp.status === 'error') {
                    setFieldsErrors(resp.errors);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Изменение имени",
                    description: err,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleChangeLogin = () => {
        setLoading(true);
        setFieldsErrors([]);
        userServices.changeLogin(loginFormData)
            .then((resp) => {
                if (resp.status === 'error') {
                    setFieldsErrors(resp.errors);
                } else {
                    notification.success({
                        message: "Отправка кода",
                        description: resp.message,
                    });
                    
                }
            })
            .catch()
            .finally(() => {
                setLoading(false);
            });
    };

    let buttonStyle = { width: '100%', height: '40px', background: '#1CAA53', borderRadius: '4px', float: 'left', borderColor: "#1CAA53" };
    let requestCodeButtonStyle = { width: '100%', height: '40px', background: '#2b3f63', borderRadius: '4px', float: 'left', borderColor: "#2b3f63", marginTop: '33px' };
    return (
        <Spin spinning={loading} tip="Загрузка...">
            <h1 className="content-header">Редактирования профиля</h1>
            <DividerBlock label="Пароль" />
            <Row gutter={[16, 24]}>
                <Col lg={6}>
                    <InputField
                        name="oldPassword"
                        label="Текущий пароль"
                        value={passwordFormData.oldPassword}
                        getFieldErrors={getFieldErrors}
                        type="password"
                        setValue={(value) => {
                            setPasswordFormData({...passwordFormData, oldPassword: value})
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col lg={6}>
                    <InputField
                        name="newPassword"
                        label="Новый пароль"
                        value={passwordFormData.newPassword}
                        getFieldErrors={getFieldErrors}
                        type="password"
                        setValue={(value) => {
                            setPasswordFormData({...passwordFormData, newPassword: value})
                        }}
                    />
                </Col>
                <Col lg={6}>
                    <InputField
                        name="newPasswordRepeat"
                        label="Новый пароль (повтор)"
                        value={passwordFormData.newPasswordRepeat}
                        getFieldErrors={getFieldErrors}
                        type="password"
                        setValue={(value) => {
                            setPasswordFormData({...passwordFormData, newPasswordRepeat: value})
                        }}
                    />
                </Col>
            </Row>
            {(user && user.isAccessTo(ADVANCED_ACCOUNT_PROTECTION)) ? (
                <Row gutter={[16, 24]}>
                    <Col lg={4}>
                        <InputField
                            name="code"
                            label="Код подтверждения"
                            getFieldErrors={getFieldErrors}
                            value={passwordFormData.code}
                            setValue={(value) => {
                                setPasswordFormData({...passwordFormData, code: value})
                            }}
                        />
                    </Col>
                    <Col lg={6}>
                        <Button
                            type="primary"
                            htmlType="button"
                            onClick={handleRequestCodOnChangePassword}
                            size="small"
                            style={requestCodeButtonStyle}
                        >
                            Получить код по СМС
                        </Button>
                    </Col>
                </Row>
            ) : null}
            <Row gutter={[16, 24]}>
                <Col lg={6}>
                    <Button
                        type="primary"
                        htmlType="button"
                        onClick={handleChangePassword}
                        size="small"
                        style={buttonStyle}
                    >
                        Изменить пароль
                    </Button>
                </Col>
            </Row>

            {(user && user.isAccessTo(COMPANY_DIRECTOR_PERMISSION)) ? (
                <>
                    <DividerBlock label="Имя пользователя" />
                    <Row gutter={[16, 24]}>
                        <Col lg={6}>
                            <InputField
                                name="name"
                                label="Имя"
                                getFieldErrors={getFieldErrors}
                                value={name}
                                setValue={setName}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col lg={6}>
                            <Button
                                type="primary"
                                htmlType="button"
                                onClick={handleChangeName}
                                size="small"
                                style={buttonStyle}
                            >
                                Изменить имя
                            </Button>
                        </Col>
                    </Row>

                    <DividerBlock label="Логин" />
                    <Row gutter={[16, 24]}>
                        <Col lg={12}>
                            <PhoneField
                                getErrors={getFieldErrors}
                                countryFieldName="oldLoginCountryID"
                                countryFieldWidth="104px"
                                phoneFieldName="oldLogin"
                                phoneFieldWidth="200px"
                                isDisabled={true}
                                title="Текущий логин"
                                selectedCountryID={loginFormData.oldLoginCountryID}
                                onChangeCountry={(value) => {
                                    setLoginFormData({
                                        ...loginFormData,
                                        oldLoginCountryID: value
                                    });
                                }}
                                phoneNumber={loginFormData.oldLogin}
                                onChangePhone={(e) => {
                                    setLoginFormData({
                                        ...loginFormData,
                                        oldLogin: e.target.value
                                    });
                                }}
                            />
                        </Col>
                        <Col lg={3}>
                            <InputField
                                name="oldLoginCode"
                                label="Код"
                                getFieldErrors={getFieldErrors}
                                value={loginFormData.oldLoginCode}
                                setValue={(value) => {
                                    setLoginFormData({...loginFormData, oldLoginCode: value})
                                }}
                            />
                        </Col>
                        <Col lg={9}>
                            <Button
                                type="primary"
                                htmlType="button"
                                disabled={loadingLoginForm.oldLoginCodeIsLoading}
                                onClick={() => {
                                    handleRequestCode(
                                        loginFormData.oldLogin,
                                        loginFormData.oldLoginCountryID,
                                        (token) => {
                                            setLoginFormData({
                                                ...loginFormData,
                                                oldLoginCodeToken: token,
                                            });
                                        }
                                    )
                                }}
                                size="small"
                                style={requestCodeButtonStyle}
                            >
                                Получить код по СМС
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col lg={12}>
                            <PhoneField
                                getErrors={getFieldErrors}
                                countryFieldName="newLoginCountryID"
                                countryFieldWidth="104px"
                                phoneFieldName="newLogin"
                                phoneFieldWidth="200px"
                                title="Новый логин"
                                selectedCountryID={loginFormData.newLoginCountryID}
                                onChangeCountry={(value) => {
                                    setLoginFormData({
                                        ...loginFormData,
                                        newLoginCountryID: value
                                    });
                                }}
                                phoneNumber={loginFormData.newLogin}
                                onChangePhone={(e) => {
                                    setLoginFormData({
                                        ...loginFormData,
                                        newLogin: e.target.value
                                    });
                                }}
                            />
                        </Col>
                        <Col lg={3}>
                            <InputField
                                name="newLoginCode"
                                label="Код"
                                getFieldErrors={getFieldErrors}
                                value={loginFormData.newLoginCode}
                                setValue={(value) => {
                                    setLoginFormData({...loginFormData, newLoginCode: value})
                                }}
                            />
                        </Col>
                        <Col lg={9}>
                            <Button
                                type="primary"
                                htmlType="button"
                                disabled={loadingLoginForm.newLoginCodeIsLoading}
                                onClick={() => {
                                    handleRequestCode(
                                        loginFormData.newLogin,
                                        loginFormData.newLoginCountryID,
                                        (token) => {
                                            setLoginFormData({
                                                ...loginFormData,
                                                newLoginCodeToken: token,
                                            });
                                        }
                                    )
                                }}
                                size="small"
                                style={requestCodeButtonStyle}
                            >
                                Получить код по СМС
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col lg={6}>
                            <Button
                                type="primary"
                                htmlType="button"
                                onClick={handleChangeLogin}
                                size="small"
                                style={buttonStyle}
                            >
                                Изменить логин
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </Spin>
    );
}