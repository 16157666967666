import React, { useState } from "react";
import { Col, Row, DatePicker, Checkbox } from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import InputField from "../../../../input-field";
import FieldBlock from "../../../../field-block";
import DividerBlock from "../../../../divider-block";
import SelectField from "../../../../select-field";

export default function GuarantorFields({
                                            stepFields,
                                            setFields,
                                            getFieldErrors,
                                            edit = false,
                                        }) {
    let dividerStyle = {
        margin: "15px 0px 45px 0px",
        display: "block",
        clear: "both",
        width: "100%",
        minWidth: "100%",
        height: "1px",
    };
    return (
        <>
            <Row gutter={[16, 24]}>
                <Col lg={8} sm={24}>
                    <InputField
                        name="guarantorFirstName"
                        title="Имя"
                        label="Имя"
                        required={true}
                        value={stepFields.guarantorFirstName}
                        setValue={(value) => {
                            setFields({ ...stepFields, guarantorFirstName: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} sm={24}>
                    <InputField
                        name="guarantorSecondName"
                        title="Фамилия"
                        label="Фамилия"
                        required={true}
                        value={stepFields.guarantorSecondName}
                        setValue={(value) => {
                            setFields({ ...stepFields, guarantorSecondName: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} sm={24}>
                    <InputField
                        name="guarantorPatronymic"
                        title="Отчество"
                        label="Отчество"
                        required={true}
                        value={stepFields.guarantorPatronymic}
                        setValue={(value) => {
                            setFields({ ...stepFields, guarantorPatronymic: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>

            <span className="divider-label">Контактные данные</span>
            <DividerBlock style={dividerStyle} />
            <Row gutter={[16, 24]}>
                <Col lg={8} sm={24}>
                    <InputField
                        name="guarantorPhone"
                        title="Моб. номер"
                        label="Моб. номер"
                        required={true}
                        value={stepFields.guarantorPhone}
                        setValue={(value) => {
                            setFields({ ...stepFields, guarantorPhone: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} sm={24}>
                    <InputField
                        name="guarantorEmail"
                        label="Email"
                        required={true}
                        value={stepFields.guarantorEmail}
                        setValue={(value) => {
                            setFields({ ...stepFields, guarantorEmail: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} sm={24}>
                <SelectField
                    title="Страна регистрации"
                    name="guarantorCountry"
                    selectedValue={stepFields.guarantorCountry}
                    required={true}
                    values={[
                        { Id: 'Россия', Name: 'Россия' },
                        { Id: 'Беларусь', Name: 'Беларусь' },
                        { Id: 'Казахстан', Name: 'Казахстан' }
                    ]}
                    onChange={(value) => {
                        setFields({ ...stepFields, guarantorCountry: value });
                    }}
                    getErrors={getFieldErrors}
                />
                </Col>
            </Row>

            <span className="divider-label">Паспортные данные</span>
            <DividerBlock style={dividerStyle} />
            <Row gutter={[16, 24]}>
                {stepFields.guarantorCountry === 'Россия' ? <Col lg={8} sm={24}>
                    <InputField
                        name="guarantorPassportSerial"
                        title="Серия удостоверения личности"
                        label="Серия удостоверения личности"
                        required={true}
                        value={stepFields.guarantorPassportSerial}
                        setValue={(value) => {
                            setFields({ ...stepFields, guarantorPassportSerial: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col> : null }
                <Col lg={stepFields.guarantorCountry === 'Россия' ? 8 : 16} sm={24}>
                    <InputField
                        name="guarantorPassportNumber"
                        title="Номер удостоверения личности"
                        label="Номер удостоверения личности"
                        required={true}
                        value={stepFields.guarantorPassportNumber}
                        setValue={(value) => {
                            setFields({ ...stepFields, guarantorPassportNumber: value });
                        }}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} sm={24}>
                    <FieldBlock
                        name="guarantorPassportDate"
                        title="Дата выдачи паспорта"
                        getErrors={getFieldErrors}
                    >
                        <DatePicker
                            style={{ width: "100%" }}
                            defaultValue={stepFields.guarantorPassportDate}
                            format="DD.MM.YYYY"
                            size="large"
                            name="guarantorPassportDate"
                            locale={localeDatePicker}
                            allowClear={false}
                            onChange={(value) =>
                                setFields({ ...stepFields, guarantorPassportDate: value })
                            }
                        />
                    </FieldBlock>
                </Col>
            </Row>
        </>
    );
}
