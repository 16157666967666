import React from "react";
import moment from "moment";
import {useEffect, useState} from "react";
import {carrierService, deliveriesService, orderDeliveryService} from "../../../services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {Button, Col, DatePicker, Modal, notification, Row, Spin} from "antd";
import ModalCreateCompanyPerson from "../create-company-person";
import SelectField from '../../select-field';
import NumberInputField from "../../number-input-field";
import BitInputField from "../../bit";
import Percent from "../../percent";
import FieldBlock from "../../field-block";
import Price from "../../price";
import '../modals.css';
import {CommentsBlock, FormatPrice} from "../../tools/delivery-tools";
import ModalSelectCarrierLegalEntity from "../select-carrier-legal-entity";
import ModalNeedApprovedLegalEntity from "../need-approved-legal-entity";

function DeliveryInfo({delivery}) {
  const prices = delivery.Prices.map((elem, index) => {
    return (
      <div className="delivery-bit-value" key={`delivery-bit-${index}`}>
        <div className="delivery-bit-label">{elem.PaymentType.Title}</div>
        <FormatPrice price={elem.Price} showPrice={true} currencySymbol={elem.PaymentType.CurrencySymbol}/>
      </div>
    );
  });

  return (
    <div className="delivery-info-modal delivery-info-modal--create-order">
      <Col lg={8} md={12} sm={12} xs={24}>
        <p className="delivery-flight">
          <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"
               style={{marginRight: "10px"}}>
            <path
              d="M7.22717 0.00375168C3.57173 -0.119438 0.569824 2.80709 0.569824 6.43469C0.569824 10.5516 4.52067 13.5394 6.73511 17.8362C6.84754 18.0544 7.16159 18.0547 7.27441 17.8365C9.27769 13.9703 12.7016 11.3777 13.3361 7.65299C13.9931 3.79863 11.1348 0.135493 7.22717 0.00375168ZM7.00444 9.80519C5.14299 9.80519 3.63394 8.2961 3.63394 6.43469C3.63394 4.57328 5.14303 3.06419 7.00444 3.06419C8.86589 3.06419 10.375 4.57328 10.375 6.43469C10.375 8.2961 8.86589 9.80519 7.00444 9.80519Z"
              fill="#1CAA53"/>
          </svg>
          Рейс: <span style={{color: "#1CAA53"}}>№ {delivery.NumberDelivery}</span></p>
        <p className="delivery-date">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
               style={{marginRight: "10px"}}>
            <path
              d="M15 4.58333V12.2917C15 13.7874 13.7874 15 12.2917 15H2.70833C1.21256 15 0 13.7874 0 12.2917V4.58333H15ZM3.54167 10C2.96637 10 2.5 10.4664 2.5 11.0417C2.5 11.617 2.96637 12.0833 3.54167 12.0833C4.11696 12.0833 4.58333 11.617 4.58333 11.0417C4.58333 10.4664 4.11696 10 3.54167 10ZM7.5 10C6.9247 10 6.45833 10.4664 6.45833 11.0417C6.45833 11.617 6.9247 12.0833 7.5 12.0833C8.0753 12.0833 8.54167 11.617 8.54167 11.0417C8.54167 10.4664 8.0753 10 7.5 10ZM3.54167 6.25C2.96637 6.25 2.5 6.71637 2.5 7.29167C2.5 7.86696 2.96637 8.33333 3.54167 8.33333C4.11696 8.33333 4.58333 7.86696 4.58333 7.29167C4.58333 6.71637 4.11696 6.25 3.54167 6.25ZM7.5 6.25C6.9247 6.25 6.45833 6.71637 6.45833 7.29167C6.45833 7.86696 6.9247 8.33333 7.5 8.33333C8.0753 8.33333 8.54167 7.86696 8.54167 7.29167C8.54167 6.71637 8.0753 6.25 7.5 6.25ZM11.4583 6.25C10.883 6.25 10.4167 6.71637 10.4167 7.29167C10.4167 7.86696 10.883 8.33333 11.4583 8.33333C12.0336 8.33333 12.5 7.86696 12.5 7.29167C12.5 6.71637 12.0336 6.25 11.4583 6.25ZM12.2917 0C13.7874 0 15 1.21256 15 2.70833V3.33333H0V2.70833C0 1.21256 1.21256 0 2.70833 0H12.2917Z"
              fill="#1CAA53"/>
          </svg>
          Дата создания: {moment(delivery.DateCreated).format("DD.MM.YYYY")}</p>
        {delivery.Comments ?
          <p className="delivery-date">
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                 style={{marginRight: "10px"}}>
              <path
                d="M11 12H5C4.73478 12 4.48043 12.1054 4.29289 12.2929C4.10536 12.4804 4 12.7348 4 13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13C12 12.7348 11.8946 12.4804 11.7071 12.2929C11.5196 12.1054 11.2652 12 11 12ZM11 8H7C6.73478 8 6.48043 8.10536 6.29289 8.29289C6.10536 8.48043 6 8.73478 6 9C6 9.26522 6.10536 9.51957 6.29289 9.70711C6.48043 9.89464 6.73478 10 7 10H11C11.2652 10 11.5196 9.89464 11.7071 9.70711C11.8946 9.51957 12 9.26522 12 9C12 8.73478 11.8946 8.48043 11.7071 8.29289C11.5196 8.10536 11.2652 8 11 8ZM13 2H11.82C11.6137 1.41645 11.2319 0.910998 10.7271 0.552938C10.2222 0.194879 9.61894 0.00173951 9 0H7C6.38106 0.00173951 5.7778 0.194879 5.27293 0.552938C4.76807 0.910998 4.38631 1.41645 4.18 2H3C2.20435 2 1.44129 2.31607 0.87868 2.87868C0.316071 3.44129 0 4.20435 0 5V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2V2ZM6 3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H9C9.26522 2 9.51957 2.10536 9.70711 2.29289C9.89464 2.48043 10 2.73478 10 3V4H6V3ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H4V5C4 5.26522 4.10536 5.51957 4.29289 5.70711C4.48043 5.89464 4.73478 6 5 6H11C11.2652 6 11.5196 5.89464 11.7071 5.70711C11.8946 5.51957 12 5.26522 12 5V4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V17Z"
                fill="#1CAA53"/>
            </svg>
            Комментарий: {delivery.Comments}</p> :
          null}
      </Col>
      <Col lg={8} md={12} sm={12} xs={24} style={{textAlign: "center"}}>
        <p
          className="delivery-address"> {delivery.PlaceLoad.City} &#10230;  {delivery.PlacesUnload[delivery.PlacesUnload.length - 1].City}</p>
      </Col>

      <Col lg={8} md={24} sm={24} xs={24}>
        <div className="delivery-left-bit">
          <p className="delivery-bit">Ставка:</p>
        </div>
        <div className="delivery-right-bit">
          {prices}
        </div>
      </Col>
    </div>
  );
}

export default function ModalCreateOrder({
                                           isShow,
                                           setIsShow,
                                           delivery,
                                           setNeedUpdateDeliveryList,
                                           setStopListUpdate,
                                           paymentType,
                                           setPaymentType
                                         }) {
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [listCompanies, setListCompanies] = useState([]);
  const [listPersons, setListPersons] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [date, setDate] = useState(moment(delivery.DateShipment));
  const [priceDelivery, setPriceDelivery] = useState(null);
  const [prepaymentPercent, setPrepaymentPercent] = useState(0);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showSelectCarrierLegalEntityModal, setSelectCarrierLegalEntityModal] = useState(false);
  const [showNeedApprove, setShowNeedApprove] = useState(false);
  const [needApproveText, setNeedAproveText] = useState('');

  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };

  useEffect(() => {
    if (isShow) {
      carrierService.getCompanies()
        .then((companies) => {
          setListCompanies(companies.LegalEntities);
          setListPersons(companies.Persons);
        })
        .catch((error) => {
          setLoadingContentModal(false);
          notification.error({
            message: "Получение данных о компаниях",
            description: error,
          });
        });
    }
  }, [isShow]);

  useEffect(() => {
    if (isShow && delivery) {
      deliveriesService.getListReturnDeliveries(delivery.Id)
        .then((companies) => {
          setLoadingContentModal(false);
          // setListPersons(companies.Persons);

          // if (!companies.LegalEntities || companies.LegalEntities.length === 0) { TODO не работает
          //     setErrorMsg('Для подачи заявки необходимо создать юр.лицо');
          // }
        })
        .catch((error) => {
          setLoadingContentModal(false);
          notification.error({
            message: "Получение данных о компаниях",
            description: error,
          });
        });
    }
  }, [isShow]);

  useEffect(() => {

    if (isShow && !loadingContentModal) {
      setLoadingContentModal(true);
      setStopListUpdate(true);
    }
  }, [isShow]);


  useEffect(() => {
    if (listCompanies && listCompanies.length) {
      setLoadingContentModal(false);
    }
  }, [listCompanies]);


  const getFieldErrors = (nameField) => {

    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  if (!loadingContentModal && listCompanies) {
    if (listCompanies.length && selectedCompany === null) {
      const firstCompany = listCompanies[0].Id;
      setSelectedCompany(firstCompany);
    }
  }

  const onClickSubmitBtnCreateOrder = (e) => {
    e.preventDefault();
    setLoadingContentModal(true);

    orderDeliveryService.createOrder(delivery.Id, selectedCompany, selectedPerson, date, priceDelivery, prepaymentPercent, paymentType)
      .then((response) => {
        if (response.status === "success") {
          notification.success({
            message: "Подача заявки",
            description: response.message,
          });

          setIsShow(false);
          setNeedUpdateDeliveryList(true);
          setStopListUpdate(false);

        } else if (response.status === "error") {
          if(response.errors) {
            setFieldsErrors(response.errors);
          } else {
            if(response.errorType === "need_shipper_contract") {
              setSelectCarrierLegalEntityModal(true);
            } else if(response.errorType === "need_agreed_order") {
              setNeedAproveText(response.message);
              setShowNeedApprove(true);
            } else {
              setErrorMsg(response.message);
            }
          }
          setLoadingContentModal(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error);
        setLoadingContentModal(false);
      });
  };

  let content;

  if (errorMsg) {
    content = (<div className="content-error-block">{errorMsg}</div>);
  } else {
    content = (
      <Spin spinning={loadingContentModal} tip="Загрузка...">
        <DeliveryInfo delivery={delivery}/>
        <Row gutter={[24, 26]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <SelectField
              title="Компания"
              name="companyId"
              selectedValue={selectedCompany}
              values={listCompanies}
              onChange={(value) => {
                setSelectedPerson(null);
                setSelectedCompany(value);
              }}
              getErrors={getFieldErrors}
              notFoundText="Отсутствуют юр.лица"
              style={{width: "100%"}}
            />
          </Col>
        </Row>
        <Row gutter={[24, 26]}>
          <Col lg={6} md={24} sm={24} xs={24}>
            <FieldBlock title="Дата загрузки" name="dateShipment" getErrors={getFieldErrors}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={date}
                format="DD.MM.YYYY"
                size="large"
                name="date_shipment"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  if (date != null) {
                    setDate(date);
                  }
                }}
              />
            </FieldBlock>
          </Col>
          <Col lg={9} md={12} sm={12} xs={24}>
            <BitInputField
              name="price" label="Ставка"
              priceDelivery={priceDelivery}
              setPrice={setPriceDelivery}
              setPaymentType={setPaymentType}
              getFieldErrors={getFieldErrors}
              delivery={delivery}
            />
          </Col>
          <Col lg={9} md={12} sm={12} xs={24}>
            <NumberInputField
              min={0}
              max={50}
              name="prepaymentPercent" label="Предоплата, %"
              value={prepaymentPercent} setValue={setPrepaymentPercent}
              getFieldErrors={getFieldErrors}
            />
            <Percent value={0} setPrepaymentPercent={setPrepaymentPercent}></Percent>
          </Col>
        </Row>
      </Spin>
    );
  }

  return (
    <>
    <Modal
      title="Подача заявки"
      okText="Подать заявку"
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        setNeedUpdateDeliveryList(true);
        setStopListUpdate(false);
      }}
      footer={[
        (errorMsg === null)
          ? (<Button htmlType="button" disabled={loadingContentModal} style={bigButtonStyle} key="submit" type="primary"
                     onClick={onClickSubmitBtnCreateOrder}>Отправить</Button>)
          : null,
      ]}
    >
      {content}
    </Modal>
    <ModalSelectCarrierLegalEntity
      isShow={showSelectCarrierLegalEntityModal}
      setIsShow={setSelectCarrierLegalEntityModal}
      delivery={delivery}
      setNeedUpdateDeliveryList={setNeedUpdateDeliveryList}
      setStopListUpdate={setStopListUpdate}
      initLegalEntity={selectedCompany}
    />
    <ModalNeedApprovedLegalEntity
          isShow={showNeedApprove}
          setIsShow={setShowNeedApprove}
          text={needApproveText}
    />
    </>
  );
}
