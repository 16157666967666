import React, {useEffect, useState} from 'react';
import Spinner from "../../../spinner";
import {Button, Col, DatePicker, Icon, Row, Table} from "antd";
import InputField from "../../../input-field";
import {reportService} from "../../../../services/report.services";
import moment from "moment";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {tableHelper} from "../../../../helpers";


export default function ReportStatsUsersTerms() {

  const [loading, setLoading] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  // параметры для построения отчета
  const [dateStart, setDateStart] = useState(moment().startOf('month'));
  const [dateEnd, setDateEnd] = useState(moment().endOf('month'));
  const [name, setName] = useState('');
  const [legalNumber, setLegalNumber] = useState('');
  // полученные данные отчета
  const [reportData, setReportData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState('');
  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService.getStatsUsersTerms(legalNumber, name, dateStart ? dateStart.format('YYYY-MM-DD') : dateStart, dateEnd ? dateEnd.format('YYYY-MM-DD') : dateEnd)
        .then((response) => {
          setReportData([]);
          let tableData = [];
          setTotalCount(response.companiesCount);
          response.data.forEach((item, index) => {
              tableData[index] = {
                number: index,
                name: item.name,
                legalNumber: item.legalNumber,
                children: []
              };

              Object.keys(item.users).forEach((k) => {
                let usr = item.users[k];
                tableData[index].children.push({
                  key: k,
                  name: usr.name,
                  termsOfUse: usr.termsOfUse ? moment(usr.termsOfUse).format("DD.MM.YYYY HH:mm"): '',
                  privacyPolicy: usr.privacyPolicy ? moment(usr.privacyPolicy).format("DD.MM.YYYY HH:mm") : ''
                });
              });

              /*
              tableData[item.distributionCenter].children.push({
                key: index,
                distributionCenter: item.legalEntitiesName,
                legalEntitiesCount: moment(item.legalEntitiesDateCreated).format("D.MM.YYYY HH:mm")
              });*/

            return null;
          });

          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(tableData);
        }).catch((errorMsg) => {
        setIsNeedUpdateReport(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    }
  }, [isNeedUpdateReport]);


  // список столбцов таблицы
  const columns = [
    {
      title: 'Компания',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: 'ИНН',
      dataIndex: 'legalNumber',
      key: 'legalNumber',
      width: '30%',
    },
    {
      title: 'Условия использования',
      dataIndex: 'termsOfUse',
      key: 'termsOfUse',
      width: '15%',
    },
    {
      title: 'Условия обработки персональных данных',
      dataIndex: 'privacyPolicy',
      key: 'privacyPolicy',
      width: '15%',
    },
  ];

  return (
    <React.Fragment>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Пользовательские соглашения»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{paddingBottom: "10px", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start" align="bottom">
              <Col lg={6} md={6} sm={12} xs={24}>
              <InputField
                name="legalNumber"
                label="ИНН"
                value={legalNumber}
                setValue={(value) => {
                  setLegalNumber(value)
                }}
                getFieldErrors={() => {
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} >
              <InputField
                name="name"
                label="Название"
                value={name}
                setValue={(value) => {
                  setName(value)
                }}
                getFieldErrors={() => {
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
                <Button type="primary"
                        htmlType="button"
                        disabled={dateStart === null || dateEnd ===null}
                        onClick={() => {
                          setLoading(true);
                          setIsNeedUpdateReport(true);
                        }}
                        style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
                >
                  Применить
                </Button>
              </Col>
          </Row>
        </div>}
        <Row gutter={12} style={{padding: "0", rowGap: "10px", marginTop: '10px', width: '100%'}} type="flex" justify="start">
          <Col lg={12} md={12} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Итого: {totalCount}</Col>
        </Row>
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{minWidth: '700px'}}
              columns={columns}
              dataSource={reportData}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size="small"
              loading={loading}
              pagination={{
                pageSize: 50
              }}
            />
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}
