import {httpRequest} from "./http-request";


export const legalEntityService = {
    getNewLegalEntityInfo,
    getLegalEntityDocsArchive,
    validateKazakhstanLegalEntityByStep,
    createKazakhstanLegalEntity,
    editKazakhstanLegalEntity,
    editRusLegalEntityData,
    editKazLegalEntityData,
    editBelLegalEntityData
}

/**
 * Редактирование данных российского юр. лица
 * LegalEntityId
 * Name
 * FullName
 * FirstName
 * SecondName
 * Patronymic
 * Bic
 * Rs
 * RegAddress
 * LegalAddress
 * MailingAddress
 * @returns {Promise<any>}
 * @param legalEntityId
 * @param legalEntityData
 */
async function editRusLegalEntityData(legalEntityId, legalEntityData) {
    const formData = new FormData();

    for (const key in legalEntityData) {
        let keyName = key;
        keyName = keyName.charAt(0).toUpperCase() + keyName.slice(1);

        formData.append(keyName, legalEntityData[key]);
    }
    formData.append('LegalEntityId', legalEntityId);
    return await httpRequest
      .post(`legal-entity/edit-rus-legal-entity-via-approve`, formData)
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Редактирование данных казахстанского юр. лица
 * LegalEntityId
 * Name
 * FullName
 * FirstName
 * SecondName
 * Patronymic
 * BankName
 * Bic
 * Rs
 * RegRegion
 * RegLocality
 * RegStreet
 * RegHouseNumber
 * RegPremiseNumber
 * RegPostalCode
 * LegalRegion
 * LegalStreet
 * LegalLocality
 * LegalHouseNumber
 * LegalPremiseNumber
 * LegalPostalCode
 * @returns {Promise<any>}
 * @param legalEntityId
 * @param legalEntityData
 */
async function editKazLegalEntityData(legalEntityId,legalEntityData) {
    const formData = new FormData();

    for (const key in legalEntityData) {
        let keyName = key;
        keyName = keyName.charAt(0).toUpperCase() + keyName.slice(1);

        formData.append(keyName, legalEntityData[key]);
    }
    formData.append('LegalEntityId', legalEntityId);

    return await httpRequest
      .post(`legal-entity/edit-kaz-legal-entity-via-approve`, formData)
      .then((response) => {
          if (response.status === 200 || response.status === 202) {
              return Promise.resolve(response.data);
          }

          return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

/**
 * Редактирование данных беларусского юр. лица
 * LegalEntityId
 * Name
 * FullName
 * FirstName
 * SecondName
 * Patronymic
 * BankName
 * Bic
 * AccountNumber
 * AddressCity
 * Address
 * MailingAddress
 * @returns {Promise<any>}
 * @param legalEntityId
 * @param legalEntityData
 */
async function editBelLegalEntityData(legalEntityId,legalEntityData) {
    const formData = new FormData();

    for (const key in legalEntityData) {
        let keyName = key;
        keyName = keyName.charAt(0).toUpperCase() + keyName.slice(1);

        formData.append(keyName, legalEntityData[key]);
    }
    formData.append('LegalEntityId', legalEntityId);

    return await httpRequest
      .post(`company/edit-belarus-legal-entity-via-approve`, formData)
      .then((response) => {
          if (response.status === 200 || response.status === 202) {
              return Promise.resolve(response.data);
          }

          return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function getNewLegalEntityInfo({inn}) {
    return await httpRequest.get(`legal-entity/get-new-legal-entity-info?inn=${inn}`).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getLegalEntityDocsArchive({id}) {
    return await httpRequest.get(`legal-entity/download-files-archive?id=${id}`).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function validateKazakhstanLegalEntityByStep(data, step, orgFormID, legalEntityID = null) {
    const formData = createKazakhstanLegalEntityFormData(data, orgFormID);
    if (legalEntityID) {
        formData.append('legalEntityID', legalEntityID);
    }
    return await httpRequest
        .post(`legal-entity/validate-kazakhstan-legal-entity-by-step?stepNumber=${step}`, formData, {})
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function createKazakhstanLegalEntity(data, orgFormID, files) {
    const formData = createKazakhstanLegalEntityFormData(data, orgFormID);

    for (const [key, value] of Object.entries(files)) {
        if (Array.isArray(value)) {
            let count = 0;
            for (const file of value) {
                let fileName = (count > 0) ? key + count : key;

                formData.append(fileName, file);
                count++;
            }
        } else {
            formData.append(key, value);
        }
    }

    return await httpRequest
        .post("legal-entity/create-kazakhstan-legal-entity", formData, {})
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function editKazakhstanLegalEntity(legalEntityID, data, orgFormID) {
    const formData = createKazakhstanLegalEntityFormData(data, orgFormID);
    formData.append('legalEntityID', legalEntityID);

    return await httpRequest
        .post("legal-entity/edit-kazakhstan-legal-entity", formData, {})
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

function createKazakhstanLegalEntityFormData(data, orgFormID) {
    const formData = new FormData();

    formData.append('orgFormID', orgFormID);
    formData.append('workWithNds', data.workWithNds);
    formData.append('dateRegistration', data.dateRegistration ? data.dateRegistration.format('YYYY-MM-DD') : data.dateRegistration);
    formData.append('name', data.name);
    formData.append('iin', data.iin ? data.iin : '');
    formData.append('bin', data.bin ? data.bin : '');
    formData.append('atiCode', data.atiCode);
    formData.append('phone', data.phone);
    formData.append('email', data.email);
    formData.append('legalReason', data.legalReason);
    formData.append('firstName', data.firstName);
    formData.append('secondName', data.secondName);
    formData.append('patronymic', data.patronymic);
    formData.append('position', data.position);
    formData.append('rs', data.rs);
    formData.append('bic', data.bic);
    formData.append('bankName', data.bankName);
    formData.append('iik', data.iik);
    formData.append('bankLegalAddress', data.bankLegalAddress);
    formData.append('correspondentBankName', data.correspondentBankName);
    formData.append('correspondentBankInn', data.correspondentBankInn);
    formData.append('correspondentBankBic', data.correspondentBankBic);
    formData.append('correspondentBankLegalAddress', data.correspondentBankLegalAddress);
    formData.append('correspondentBankAccount', data.correspondentBankAccount);
    formData.append('correspondentBankCheckingAccount', data.correspondentBankCheckingAccount);
    formData.append('registrationNumber', data.registrationNumber);
    formData.append('certificateSerial', data.certificateSerial);
    formData.append('certificateNumber', data.certificateNumber);

    formData.append('legalRegion', data.legalRegion);
    formData.append('legalLocality', data.legalLocality);
    formData.append('legalStreet', data.legalStreet);
    formData.append('legalHouseNumber', data.legalHouseNumber);
    formData.append('legalPremiseNumber', data.legalPremiseNumber);
    formData.append('legalPostalCode', data.legalPostalCode);
    formData.append('regRegion', data.isRegEqualsLegal ? data.legalRegion : data.regRegion);
    formData.append('regLocality', data.isRegEqualsLegal ? data.legalLocality : data.regLocality);
    formData.append('regStreet', data.isRegEqualsLegal ? data.legalStreet : data.regStreet);
    formData.append('regHouseNumber', data.isRegEqualsLegal ? data.legalHouseNumber : data.regHouseNumber);
    formData.append('regPremiseNumber', data.isRegEqualsLegal ? data.legalPremiseNumber : data.regPremiseNumber);
    formData.append('regPostalCode', data.isRegEqualsLegal ? data.legalPostalCode : data.regPostalCode);

    formData.append('guarantorFirstName', data.guarantorFirstName);
    formData.append('guarantorSecondName', data.guarantorSecondName);
    formData.append('guarantorPatronymic', data.guarantorPatronymic);
    formData.append('guarantorPassportSerial', data.guarantorPassportSerial);
    formData.append('guarantorPassportNumber', data.guarantorPassportNumber);
    formData.append('guarantorPassportDate', data.guarantorPassportDate);
    formData.append('guarantorCountry', data.guarantorCountry);
    formData.append('guarantorEmail', data.guarantorEmail);
    formData.append('guarantorPhone', data.guarantorPhone);

    return formData;
}
