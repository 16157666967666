import React, {useState} from 'react';
import {Col, Row, DatePicker, Checkbox} from 'antd';
import InputField from '../../../../input-field';
import DividerBlock from "../../../../divider-block";
import SelectField from "../../../../select-field";

export default function MainDataFields({ stepFields, setFields, getFieldErrors, orgFormId, edit = false }) {
    const [notRegisteredATI, setNotRegisteredATI] = useState(false);

    const firstToUpper = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const ndsList = [
        {
            Id: 1,
            Name: 'С НДС'
        },
        {
            Id: 2,
            Name: 'Без НДС'
        }
    ];

    return (
        <>
          <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
            <Col lg={8} sm={24}>
              <InputField
                name="name"
                title='Название'
                label="Название"
                required={true}
                value={stepFields.name}
                setValue={(value) => {setFields({...stepFields, name: value});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
              <Col lg={8} sm={24}>
                  <InputField
                      name="legalNumber"
                      title='Юридический номер'
                      label="УНП"
                      required={true}
                      value={stepFields.legalNumber}
                      setValue={(value) => {setFields({...stepFields, legalNumber: value});}}
                      getFieldErrors={getFieldErrors}
                  />
              </Col>
              <Col lg={8} sm={24}>
                  <SelectField
                      title="НДС"
                      name="workWithNds"
                      selectedValue={stepFields.workWithNds}
                      values={ndsList}
                      onChange={(value) => {
                          setFields({
                              ...stepFields,
                              workWithNds: value,
                          });
                      }}
                      getErrors={getFieldErrors}
                      placeholder="НДС"
                      notFoundText={null}
                      showArrow={true}
                      style={{ width: "100%" }}
                  />
              </Col>
          </Row>
          <DividerBlock label="Контактные данные подписанта" />
          <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
            <Col lg={8} sm={24}>
              <InputField
                name="firstName"
                title='Имя'
                label="Имя"
                required={true}
                value={stepFields.firstName}
                setValue={(value) => {setFields({...stepFields, firstName: firstToUpper(value)});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                name="secondName"
                title='Фамилия'
                label="Фамилия"
                required={true}
                value={stepFields.secondName}
                setValue={(value) => {setFields({...stepFields, secondName: firstToUpper(value)});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                name="patronymic"
                title='Отчество'
                label="Отчество"
                required={true}
                value={stepFields.patronymic}
                setValue={(value) => {setFields({...stepFields, patronymic: firstToUpper(value)});}}
                getFieldErrors={getFieldErrors}
              />
            </Col>
              { orgFormId !== 16 ?
                  <>
                      <Col lg={8} sm={24} >
                          <InputField name="position" label="Должность" value={stepFields.position} setValue={value => setFields({...stepFields, position: firstToUpper(value)})} getFieldErrors={getFieldErrors} />
                      </Col>
                      <Col lg={8} sm={24} >
                          <SelectField
                              title="Основание действий"
                              name="legalReason"
                              selectedValue={stepFields.legalReason}
                              values={stepFields.legalReasonsList}
                              onChange={(value) => {
                                  setFields({
                                      ...stepFields,
                                      legalReason: value,
                                  });
                              }}
                              getErrors={getFieldErrors}
                              placeholder="Укажите основание действий"
                              notFoundText={null}
                              showArrow={true}
                              style={{ width: "100%" }}
                          />
                      </Col>
                  </> :
                  <>
                      <Col lg={8} sm={24} >
                          <InputField
                              name="legalReason"
                              isDisabled={true}
                              label="Основание действий"
                              value={'Свидетельство о регистрации ИП'}
                              setValue={value => setFields({...stepFields, legalReason: value})}
                              getFieldErrors={getFieldErrors}
                          />
                      </Col>
                  </>
              }
          </Row>
          <DividerBlock label="Банк" />
          <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
            <Col lg={8} sm={24}>
              <InputField
                  name="bankName"
                  title='Название банка'
                  label="Название банка"
                  required={true}
                  value={stepFields.bankName}
                  setValue={(value) => {setFields({...stepFields, bankName: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="accountNumber"
                  title='Номер счёта'
                  label="Номер счёта"
                  required={true}
                  value={stepFields.accountNumber}
                  setValue={(value) => {setFields({...stepFields, accountNumber: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
              <Col lg={8} sm={24}>
                  <InputField
                      name="bankUnp"
                      label="УНП банка"
                      title="УНП банка"
                      required={true}
                      value={stepFields.bankUnp}
                      setValue={(value) => {setFields({...stepFields, bankUnp: value});}}
                      getFieldErrors={getFieldErrors}
                  />
              </Col>
              <Col lg={8} sm={24}>
                  <InputField
                      name="bic"
                      label="БИК"
                      title="БИК"
                      required={true}
                      value={stepFields.bic}
                      setValue={(value) => {setFields({...stepFields, bic: value});}}
                      getFieldErrors={getFieldErrors}
                  />
              </Col>
            <Col lg={8} sm={24}>
              <InputField
                  name="swift"
                  label="SWIFT"
                  required={true}
                  value={stepFields.swift}
                  setValue={(value) => {setFields({...stepFields, swift: value});}}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
              <Col lg={24} sm={24}>
                  <InputField
                      name="bankAddress"
                      label="Юридический адрес банка"
                      title="Юридический адрес банка"
                      required={true}
                      value={stepFields.bankAddress}
                      setValue={(value) => {setFields({...stepFields, bankAddress: value});}}
                      getFieldErrors={getFieldErrors}
                  />
              </Col>
          </Row>

        <DividerBlock label="Банк-корреспондент"/>
        <Row gutter={[{ xs: 0, sm: 0, md: 16}, 24]}>
            <Col lg={8} md={8} sm={24} xs={24}>
                <InputField
                    name="correspondentBankName"
                    title="Название банка"
                    label="Название банка"
                    required={true}
                    value={stepFields.correspondentBankName}
                    setValue={(value) => {
                        setFields({ ...stepFields, correspondentBankName: value });
                    }}
                    getFieldErrors={getFieldErrors}
                />
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
                <InputField
                    name="correspondentBankInn"
                    title="ИНН"
                    label="ИНН"
                    required={true}
                    value={stepFields.correspondentBankInn}
                    setValue={(value) => {
                        setFields({ ...stepFields, correspondentBankInn: value });
                    }}
                    getFieldErrors={getFieldErrors}
                />
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
                <InputField
                    name="correspondentBankBic"
                    label="БИК"
                    title="БИК"
                    required={true}
                    value={stepFields.correspondentBankBic}
                    setValue={(value) => {
                        setFields({ ...stepFields, correspondentBankBic: value });
                    }}
                    getFieldErrors={getFieldErrors}
                />
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
                <InputField
                    name="correspondentBankAccount"
                    label="Кор/сч"
                    title="Кор/сч"
                    required={true}
                    value={stepFields.correspondentBankAccount}
                    setValue={(value) => {
                        setFields({ ...stepFields, correspondentBankAccount: value });
                    }}
                    getFieldErrors={getFieldErrors}
                />
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
                <InputField
                    name="correspondentBankCheckingAccount"
                    label="Р/с"
                    title="Р/с"
                    required={true}
                    value={stepFields.correspondentBankCheckingAccount}
                    setValue={(value) => {
                        setFields({ ...stepFields, correspondentBankCheckingAccount: value });
                    }}
                    getFieldErrors={getFieldErrors}
                />
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
                <InputField
                    name="correspondentBankLegalAddress"
                    label="Юр. адрес"
                    title="Юр. адрес"
                    required={true}
                    value={stepFields.correspondentBankLegalAddress}
                    setValue={(value) => {
                        setFields({ ...stepFields, correspondentBankLegalAddress: value });
                    }}
                    getFieldErrors={getFieldErrors}
                />
            </Col>
        </Row>


          <DividerBlock label="Реквизиты" />
          <Row>
          </Row>
            <Row>
                <Col lg={8} sm={24}>
                    <InputField
                        name="phone"
                        title='Моб. номер'
                        label="Моб. номер"
                        required={true}
                        value={stepFields.phone}
                        setValue={(value) => {setFields({...stepFields, phone: value});}}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} sm={24}>
                    <InputField
                        name="email"
                        label="Email"
                        required={true}
                        value={stepFields.email}
                        setValue={(value) => {setFields({...stepFields, email: value});}}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={8} sm={24}>
                    <InputField
                        name="atiCode"
                        label="Код АТИ"
                        isDisabled={notRegisteredATI}
                        required={true}
                        value={stepFields.atiCode}
                        setValue={(value) => {setFields({...stepFields, atiCode: value});}}
                        getFieldErrors={getFieldErrors}
                    />
                    <Checkbox
                        name="notRegisteredATI"
                        checked={notRegisteredATI}
                        onChange={(e) => {
                            setFields({
                                ...stepFields,
                                atiCode: e.target.checked ? 'Без АТИ' : ''
                            });
                            setNotRegisteredATI(e.target.checked)
                        }}
                        style={{fontSize: "10pt", paddingTop: "15px"}}
                    >
                        Не зарегистрирован на АТИ
                    </Checkbox>
                </Col>
            </Row>
        </>
    );
}
