import React, {useEffect, useState} from "react";
import MainDataFields from "./steps/main-data-fields";
import {legalEntityService} from "../../../../services/legal-entity.service";
import {notification} from "antd";
import AddressesFields from "./steps/addresses-fields";
import DocsFields from "./steps/docs-fields";
import {companyService} from "../../../../services";
import ModalListShipCompanies from "../../list-ship-companies";
import GuarantorFields from "./steps/guarantor-fields";

export default function CreateKazakhstanLegalEntity({
                                                        isActive = false,

                                                        currentStep,
                                                        setCurrentStep,

                                                        orgFormID,
                                                        workWithNds,

                                                        setLoading,
                                                        setShowModal,
                                                        setStepsList,

                                                        changeStepEvent,
                                                        setChangeStepEvent,

                                                        createLegalEntityEvent,
                                                        setCreateLegalEntityEvent,
                                                    }) {
    const [fieldsErrors, setFieldsErrors] = useState({});

    const [showModalListShipCompanies, setShowModalListShipCompanies] = useState(false);
    const [legalEntityID, setLegalEntityID] = useState(null);

    const [isProprietor, setIsProprietor] = useState(true);
    const [mainDataFields, setMainDataFields] = useState({
        orgFormID: orgFormID,
        workWithNds: workWithNds,

        legalReason: '',
        legalReasonsList: [
            {id: 'Устав', name: 'Устав'},
            {id: 'Приказ', name: 'Приказ'},
            {id: 'Доверенность', name: 'Доверенность'}
        ],

        name: '',
        dateRegistration: '',
        firstName: '',
        secondName: '',
        patronymic: '',
        position: '',
        email: '',
        phone: '',
        bankName: '',
        rs: '',
        bic: '',
        iik: '',
        bankLegalAddress: '',
        correspondentBankName: '',
        correspondentBankInn: '',
        correspondentBankBic: '',
        correspondentBankLegalAddress: '',
        correspondentBankAccount: '',
        correspondentBankCheckingAccount: '',
        iin: '',
        bin: '',
        atiCode: '',
        registrationNumber: '',
        certificateSerial: '',
        certificateNumber: '',
    });

    const [addressesFields, setAddressesFields] = useState({
        legalRegion: '',
        legalLocality: '',
        legalStreet: '',
        legalHouseNumber: '',
        legalPremiseNumber: '',
        legalPostalCode: '',

        isRegEqualsLegal: false,
        regRegion: '',
        regLocality: '',
        regStreet: '',
        regHouseNumber: '',
        regPremiseNumber: '',
        regPostalCode: '',
    });

    const [guarantorFields, setGuarantorFields] = useState({
        guarantorFirstName: '',
        guarantorSecondName: '',
        guarantorPatronymic: '',
        guarantorPassportSerial: '',
        guarantorPassportNumber: '',
        guarantorPassportDate: '',
        guarantorPhone: '',
        guarantorEmail: '',
        guarantorCountry: 'Казахстан',
    });

    const [docsFields, setDocsFields] = useState({});

    useEffect(() => {
        if (isActive && orgFormID) {
            const isProp = (orgFormID === 27);
            setIsProprietor(isProp);
            if (isProp) {
                setMainDataFields({...mainDataFields, bin: ''})
                setStepsList([
                    'Основная информация',
                    'Адреса',
                    'Документы'
                ]);
            } else {
                setMainDataFields({...mainDataFields, iin: ''})
                setStepsList([
                    'Основная информация',
                    'Адреса',
                    'Поручитель',
                    'Документы'
                ]);
            }
        }
    }, [isActive, orgFormID]);

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };

    useEffect(() => {
        if (changeStepEvent) {
            if (changeStepEvent === 'next') {
                nextStep();
            } else if (changeStepEvent === 'previous') {
                previousStep();
            }

            setChangeStepEvent('');
        }

        setDocsFields({
            certificateOfStateRegistration: [], // Свидетельство о государственной регистрации юридического лица
            partnerCard: [], // Карточка контрагента – сведения о банковских реквизитах, номера телефонов, адреса офисов
            copyOfThePassport: [], // Паспорт с пропиской
            powerOfAttorneyForAPerson: [], // Доверенность на лицо, подтверждающая полномочия лица, подписывающего договор
            protocolOfTheGeneralMeetingOfParticipants: [], // Протокол общего собрания участников юридического лица с решением об избрании руководителя
        });
    }, [changeStepEvent]);

    const nextStep = () => {
        let stepData = (step) => {
            let stepFields = {
                1: mainDataFields,
                2: addressesFields,
                3: guarantorFields,
                4: docsFields,
            };

            return stepFields[step];
        };

        setLoading(true);

        legalEntityService
            .validateKazakhstanLegalEntityByStep(stepData(currentStep), currentStep, orgFormID)
            .then(resp => {
                if (resp.status === 'success') {
                    if (currentStep < 4) {
                        if ((currentStep + 1) === 3 && isProprietor) {
                            setCurrentStep(currentStep + 2);
                        } else {
                            setCurrentStep(currentStep + 1);
                        }
                        setFieldsErrors([]);
                    }
                } else {
                    setFieldsErrors(resp.errors);
                    notification.error({
                        message: "Валидация юр.лица",
                        description: resp.message,
                    });
                }
            })
            .catch(err => {
                notification.error({
                    message: "Валидация юр.лица",
                    description: err,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const previousStep = () => {
        if (currentStep > 0) {
            if (currentStep === 4 && isProprietor) {
                setCurrentStep(currentStep - 2);
            } else {
                setCurrentStep(currentStep - 1);
            }
        } else {
            setCurrentStep(0);
        }
    };

    useEffect(() => {
        if (createLegalEntityEvent) {
            setLoading(true);

            let data = Object.assign(mainDataFields, guarantorFields, addressesFields);
            legalEntityService
                .createKazakhstanLegalEntity(data, orgFormID, docsFields)
                .then(response => {
                    if (response.code === 400) {
                        notification.error({
                            message: "Создание юридического лица",
                            description: response.message,
                        });
                        setFieldsErrors(response.errors);
                    } else {
                        notification.success({
                            message: "Создание юридического лица",
                            description: response.message,
                        });

                        setShowModalListShipCompanies(true);
                        setLegalEntityID(response.data);
                        setFieldsErrors({});
                    }

                    setCreateLegalEntityEvent(false);
                    setLoading(false);
                })
                .catch(errMsg => {
                    notification.error({
                        message: "Создание юридического лица",
                        description: errMsg,
                    });

                    setLoading(false);
                    setCreateLegalEntityEvent(false);
                });
        }
    }, [createLegalEntityEvent]);


    const renderStep = stepNumber => {
        switch (stepNumber) {
            case 1:
                return (
                    <MainDataFields
                        stepFields={mainDataFields}
                        setFields={setMainDataFields}
                        getFieldErrors={getFieldErrors}
                        isProprietor={isProprietor}
                    />
                );
            case 2:
                return (
                    <AddressesFields
                        stepFields={addressesFields}
                        setFields={setAddressesFields}
                        getFieldErrors={getFieldErrors}
                    />
                );
            case 3:
                return (
                    <GuarantorFields
                        stepFields={guarantorFields}
                        setFields={setGuarantorFields}
                        getFieldErrors={getFieldErrors}
                    />
                );
            case 4:
                return (
                    <DocsFields
                        stepFields={docsFields}
                        setFields={setDocsFields}
                        getFieldErrors={getFieldErrors}
                        organizationalForm={orgFormID}
                        isNeedPowerOfAttorneyForAPerson={mainDataFields.legalReason === 'Доверенность'}
                    />
                );
            default:
                return <div />;
        }
    };

    return (
        <div>
            {renderStep(currentStep)}
            <ModalListShipCompanies
                isShow={showModalListShipCompanies}
                setIsShow={setShowModalListShipCompanies}
                legalEntityId={legalEntityID}
                setIsShowParent={setShowModal}
            />
        </div>
    );
}