import React, { useEffect } from "react";
import {FormatPrice, FullDate} from "../tools/delivery-tools";
import {userServices} from "../../services";
import {
    DELIVERY_AGREED,
    DELIVERY_AWAITING_LOADING,
    DELIVERY_BOOKED,
    DELIVERY_ENROUTE,
    DELIVERY_LOADING
} from "../../helpers/delivery_status.helper";

export default function PriceElement({ delivery, permissions }) {
    const user = userServices.getUser();
    if(delivery.Status === DELIVERY_AWAITING_LOADING || delivery.Status === DELIVERY_ENROUTE || delivery.Status === DELIVERY_LOADING || delivery.Status === DELIVERY_AGREED
        || (user.info.userType === 'inner' && delivery.Status === DELIVERY_BOOKED)) {
        return null;
    }

    const canCreateOrder = (delivery.Status === DELIVERY_BOOKED && user.info.userType === "carrier") || user.info.userType === "inner" || (permissions !== undefined && permissions.IsCanCreateOrder !== false);

    const pricesArray = delivery.Prices ?
      Array.isArray(delivery.Prices) ?  delivery.Prices : Object.values(delivery.Prices) :
      null;

    const prices = pricesArray ? pricesArray.map((elem, key) => {
        return (
            <div className="price-line clrfx" key={key}>
                <div className="ls">{elem.PaymentType.Title}</div>
                <FormatPrice price={elem.Price} showPrice={canCreateOrder} currencySymbol={elem.PaymentType.CurrencySymbol} />
            </div>
        );
    }) : null;

    if (!prices) {
        return null;
    }

    return (
        <div className="informer-item pricer">
            <div className="h-informer">Ставка</div>
            <div className="b-informer">
                {prices}
            </div>
        </div>
    );


}
