import React, { useState, useEffect } from "react";
import {Button, Modal, Row, Spin, Form, Steps, notification } from "antd";
import {companyService} from "../../../../services";
import MainDataFields from "./steps/main-data-fields";
import DocsFields from "./steps/docs-fields";
import ModalListShipCompanies from "../../list-ship-companies";
import GuarantorFields from "./steps/guarantor-fields";
import AddressesFields from "./steps/addresses-fields";

export default function CreateBelarusLegalEntity({
                                                   isActive = false,

                                                   currentStep,
                                                   setCurrentStep,

                                                   orgFormId,
                                                   countryId,
                                                   workWithNds,

                                                   setLoading,
                                                   setShowModal,
                                                   setStepsList,

                                                   changeStepEvent,
                                                   setChangeStepEvent,

                                                   createLegalEntityEvent,
                                                   setCreateLegalEntityEvent,
                                                 }) {
  const [organisationFormsList, setOrganisationFormsList] = useState([]);
  const [countryList] = useState([]);
  const [fieldsErrors, setFieldsErrors] = useState({});

  const [showModalListShipCompanies, setShowModalListShipCompanies] = useState(false);
  const [legalEntityId, setLegalEntityId] = useState(null);

  // Данные по юр.лицу
  const [mainDataFields, setMainDataFields] = useState({
    orgFormId: orgFormId,
    country: countryId,
    workWithNds: workWithNds,

    legalReasonsList: [
      {
        Id: 'Устав',
        Name: 'Устав'
      },
      {
        Id: 'Приказ',
        Name: 'Приказ'
      },
      {
        Id: 'Доверенность (ООО)',
        Name: 'Доверенность (ООО)'
      }
    ],

    name: '',
    fullName: '',
    firstName: '',
    secondName: '',
    patronymic: '',
    email: '',
    phone: '',
    bankName: '',
    bankAddress: '',
    accountNumber: '',
    swift: '',
    bankUnp: '',
    bic: '',
    correspondentBankName: '',
    correspondentBankInn: '',
    correspondentBankBic: '',
    correspondentBankLegalAddress: '',
    correspondentBankAccount: '',
    correspondentBankCheckingAccount: '',
    position: '',
    legalNumber: '',
    legalReason: '',
    atiCode: '',
  });

  const [addressesFields, setAddressesFields] = useState({
    regRegion: '',
    regLocality: '',
    regStreet: '',
    regHouseNumber: '',
    regPremiseNumber: '',
    regPostalCode: '',

    factAddressCheckBox: false,
    mailingRegion: '',
    mailingLocality: '',
    mailingStreet: '',
    mailingHouseNumber: '',
    mailingPremiseNumber: '',
    mailingPostalCode: '',
  });

  const [guarantorFields, setGuarantorFields] = useState({
    guarantorFirstName: '',
    guarantorSecondName: '',
    guarantorPatronymic: '',
    guarantorPassportSerial: '',
    guarantorPassportNumber: '',
    guarantorPassportDate: '',
    guarantorPassportRegistration: '',
    guarantorAddressFact: '',
    guarantorAddressRegistration: '',
    guarantorCountry: 'Беларусь',
    guarantorPhone: '',
    guarantorEmail: '',
  });

  const [docsFields, setDocsFields] = useState({
    // Общие
    certificateOfStateRegistration: [], // Свидетельство о государственной регистрации юридического лица
    passport: [], // Паспорт с пропиской
    specialPermitForTheRightToCarryOutActivities: [], // Специальное разрешение (лицензия) на право осуществления деятельности в области автомобильного транспорта
    partnerCard: [], // Карточка контрагента – сведения о банковских реквизитах, номера телефонов, адреса офисов
    residenceCertificate: [], // Справка о постоянном местонахождении Residence Certificate (в целях избежания двойного налогообложения) (по форме согласно Приложение 11 к постановлению МНС Республики Беларусь от 26.04.2013 № 14)

    // Не ИП
    articlesOfAssociation: [], // Устав в актуальной редакции
    documentsConfirmingOwnershipOfOfficePremises: [], // Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность (свидетельства о государственной регистрации, договоры аренды)
    copyOfBankCard: [], // Копия банковской карточки с образцами подписи и оттиска печати с которой можно сличить подпись и печать, проставленную в договоре
  });

  const getFieldErrors = (fieldName) => {
    return typeof fieldsErrors[fieldName] !== "undefined"
        ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
          return fieldErrors + "; " + error;
        })
        : null;
  };

  // Сброс документов при смене шага
  useEffect(() => {
    setDocsFields({
      // Общие
      certificateOfStateRegistration: [], // Свидетельство о государственной регистрации юридического лица
      passport: [], // Паспорт с пропиской
      specialPermitForTheRightToCarryOutActivities: [], // Специальное разрешение (лицензия) на право осуществления деятельности в области автомобильного транспорта
      partnerCard: [], // Карточка контрагента – сведения о банковских реквизитах, номера телефонов, адреса офисов
      residenceCertificate: [], // Справка о постоянном местонахождении Residence Certificate (в целях избежания двойного налогообложения) (по форме согласно Приложение 11 к постановлению МНС Республики Беларусь от 26.04.2013 № 14)

      // Не ИП
      articlesOfAssociation: [], // Устав в актуальной редакции
      documentsConfirmingOwnershipOfOfficePremises: [], // Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность (свидетельства о государственной регистрации, договоры аренды)
      copyOfBankCard: [], // Копия банковской карточки с образцами подписи и оттиска печати с которой можно сличить подпись и печать, проставленную в договоре
    });
  }, [currentStep]);

  useEffect(() => {
    if (isActive) {
      if (orgFormId === 16) {
        setStepsList([
          'Основная информация',
          'Адреса',
          'Документы'
        ]);
      } else {
        setStepsList([
          'Основная информация',
          'Адреса',
          'Поручитель',
          'Документы'
        ]);
      }
    }
  }, [isActive]);

  useEffect(() => {
    if (changeStepEvent) {
      if (changeStepEvent === 'next') {
        nextStep();
      } else if (changeStepEvent === 'previous') {
        previousStep();
      }

      setChangeStepEvent('');
    }

    setDocsFields({
      // Общие
      certificateOfStateRegistration: [], // Свидетельство о государственной регистрации юридического лица
      passport: [], // Паспорт с пропиской
      specialPermitForTheRightToCarryOutActivities: [], // Специальное разрешение (лицензия) на право осуществления деятельности в области автомобильного транспорта
      partnerCard: [], // Карточка контрагента – сведения о банковских реквизитах, номера телефонов, адреса офисов
      residenceCertificate: [], // Справка о постоянном местонахождении Residence Certificate (в целях избежания двойного налогообложения) (по форме согласно Приложение 11 к постановлению МНС Республики Беларусь от 26.04.2013 № 14)

      // Не ИП
      articlesOfAssociation: [], // Устав в актуальной редакции
      documentsConfirmingOwnershipOfOfficePremises: [], // Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность (свидетельства о государственной регистрации, договоры аренды)
      copyOfBankCard: [], // Копия банковской карточки с образцами подписи и оттиска печати с которой можно сличить подпись и печать, проставленную в договоре
    });
  }, [changeStepEvent]);

  useEffect(() => {
    if (createLegalEntityEvent) {
      setLoading(true);

      let data = Object.assign(mainDataFields, guarantorFields, addressesFields);
      companyService.createBelarusLegalEntity(data, docsFields, countryId, orgFormId)
          .then(response => {
            if (response.code === 400) {
              notification.error({
                message: "Создание юридического лица",
                description: response.message,
              });
              setFieldsErrors(response.errors);
            } else {
              notification.success({
                message: "Создание юридического лица",
                description: response.message,
              });

              setShowModalListShipCompanies(true);
              setLegalEntityId(response.data);
              setFieldsErrors({});

              // setIsNeedUpdate(true);
            }

            setCreateLegalEntityEvent(false);
            setLoading(false);
          })
          .catch(errMsg => {
            notification.error({
              message: "Создание юридического лица",
              description: errMsg,
            });

            setLoading(false);
            setCreateLegalEntityEvent(false);
          });
    }
  }, [createLegalEntityEvent]);

  const nextStep = () => {
    let stepData = (currentStep) => {
      let stepFields = {
        1: mainDataFields,
        2: addressesFields,
        3: guarantorFields,
        4: docsFields,
      };

      // Баг. Без этого виесто stepFields undefiend
      console.log(stepFields, currentStep, orgFormId);

      return stepFields[currentStep];
    };

    setLoading(true);

    companyService
        .createBelarusLegalEntityValidateStepData(stepData(currentStep), currentStep, orgFormId, countryId)
        .then(response => {
          setLoading(false);

          if (response.status === 'success') {
            if (currentStep < 4) {
              if ((currentStep + 1) === 3 && orgFormId === 16) {
                setCurrentStep(currentStep + 2);
              } else {
                setCurrentStep(currentStep + 1);
              }
              setFieldsErrors([]);
            }
          } else {
            setFieldsErrors(response.errors);
          }
        })
        .catch(error => {
          setLoading(false);
          notification.error({
            message: "Создание юр.лица",
            description: error,
          });
        });
  };

  const previousStep = () => {
    if (currentStep > 0) {
      if (currentStep === 4 && orgFormId === 13) {
        setCurrentStep(currentStep - 2);
      } else {
        setCurrentStep(currentStep - 1);
      }
    } else {
      setCurrentStep(0);
    }
  };


  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
            <MainDataFields
                setFields={setMainDataFields}
                stepFields={mainDataFields}
                getFieldErrors={getFieldErrors}
                // organisationFormsList={organisationFormsList}
                // countryList={countryList}
                orgFormId={orgFormId}
            />
        );
      case 2:
        return (
            <AddressesFields
                setFields={setAddressesFields}
                stepFields={addressesFields}
                getFieldErrors={getFieldErrors}
            />
        );
      case 3:
        return (
            <GuarantorFields
                setFields={setGuarantorFields}
                stepFields={guarantorFields}
                getFieldErrors={getFieldErrors}
            />
        );
      case 4:
        return (
            <DocsFields
                setFields={setDocsFields}
                stepFields={docsFields}
                getFieldErrors={getFieldErrors}
                organizationalForm={mainDataFields.orgFormId}
                isNdsPayer={mainDataFields.workWithNds}
            />
        );
      default:
        return <div />;
    }

  };

  return (
      <div>
        {renderCurrentStep()}
        <ModalListShipCompanies isShow={showModalListShipCompanies} setIsShow={setShowModalListShipCompanies} legalEntityId={legalEntityId} setIsShowParent={setShowModal} />
      </div>
  );
}
