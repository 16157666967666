import React from "react";
import {useEffect, useState} from "react";
import {companyService} from "../../../services";
import {Button, Col, Modal, notification, Row, Spin} from "antd";

export default function ModalRequestAllowInternational({
                                           isShow,
                                           setIsShow,
                                           delivery,
                                           setNeedUpdateDeliveryList,
                                           setStopListUpdate,
                                         }) {
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [listCompanies, setListCompanies] = useState([]);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showOrderToShipperCompanyModal, setShowOrderToShipperCompanyModal] = useState(false);
  const [legalEntityId, setLegalEntityId] = useState(null);
  const currentShipperCompanyId = delivery.CustomerCompanyId;

  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };

  let bigButtonCancelStyle = {
    width: '200px', height: '43px', background: '#BE1C1C', position: 'static', borderRadius: '10px'
  };

  const textStyle = {
    textAlign: 'center',
    color: 'black'
  };


  useEffect(() => {
    if (listCompanies && listCompanies.length) {
      setLoadingContentModal(false);
    }
  }, [listCompanies]);


  const getFieldErrors = (nameField) => {

    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  const onSignUp = () => {
    companyService.requestAllowInternational(currentShipperCompanyId)
      .then((response) => {
        notification.success({
          message: "Запрос аккредитации", description: response.message,
        });
        setIsShow(false);
      })
      .catch((err) => {
        notification.error({
          message: "Запрос аккредитации", description: err,
        });
      })
      .finally(() => {
      })
  }

  let content;

  const closeModal = () => {
    setIsShow(false);
    setNeedUpdateDeliveryList(false);
    setStopListUpdate(false);
  }

  if (errorMsg) {
    content = (<div className="content-error-block">{errorMsg}</div>);
  } else {
    content = (
      <Spin spinning={loadingContentModal} tip="Загрузка...">
        <Row className="add-padding-10 -centered">
            <h2 style={textStyle}>Для подачи заявки на международный рейс Вам необходимо направить запрос на аккредитацию в компанию <span>{delivery.CustomerCompany}</span></h2>
        </Row>
      </Spin>
    );
  }

  return (
    <>
    <Modal
      title="Запрос аккредитации"
      okText="Подать заявку"
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        setNeedUpdateDeliveryList(false);
        setStopListUpdate(false);
      }}
      footer={[
        (errorMsg === null)
          ? (<Button htmlType="button" disabled={loadingContentModal} style={bigButtonStyle} key="submit" type="primary"
            onClick={() => onSignUp()}>Отправить</Button>)
          : null,
      ,<Button onClick={() => closeModal()} htmlType="button" style={bigButtonCancelStyle} type="primary">Отмена</Button>]}
    >
      {content}
    </Modal>
    </>
  );
}
