import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Row, Spin, Form, notification, Icon } from "antd";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import {carrierService, deliveriesService, driverService, trailService, truckService} from "../../../services";
import ModalCreateDriver from "../../modals/create-driver";
import ModalCreateTruck from "../../modals/create-truck";
import ModalCreateTrail from "../../modals/create-trail";
import SelectField from "../../select-field";
import FieldBlock from "../../field-block";
import InputField from "../../input-field";
import ModalCreateForeignPartner from "../create-foreign-partner";
import {DELIVERY_CONTAINER_TYPE} from "../../../helpers/delivery.helper";

export default function Step1FilledData({
    showModal,
    setIsShowModal,
    companyId,
    filledData,
    setFilledData,
    setModalStep,
    setButtonsModal,
    deliveryDateShipmentRus,
    deliveryPrice,
    deliveryRegionLoad,
    deliveryRegionUnload,
    isForeignDelivery,
    carryingCapacityValues,
    deliveryContractType
}) {

    const [loading, setLoading] = useState(false);

    const [companyInfo, setCompanyInfo] = useState(null);
    const [listDrivers, setListDrivers] = useState([]);
    const [listTrucks, setListTrucks] = useState([]);
    const [listTrails, setListTrails] = useState([]);
    const [foreignPartners, setForeignPartners] = useState([]);
    const [foreignPartnersLoad, setForeignPartnersLoad] = useState(false);
    const [selectedTruckInfo, setSelectedTruckInfo] = useState(null);
    const [trailDisabled, setTrailDisabled] = useState(true);
    const [isNeedTrail, setIsNeedTrail] = useState(true);
    const [isNeedUpdateList, setIsNeedUpdateList] = useState(null);

    const [showModalCreateDriver, setShowModalCreateDriver] = useState(false);
    const [showModalCreateTruck, setShowModalCreateTruck] = useState(false);
    const [showModalCreateTrail, setShowModalCreateTrail] = useState(false);
    const [showModalCreateForeignPartner, setShowModalCreateForeignPartner] = useState(false);

    const [fieldsErrors, setFieldsErrors] = useState({});
    /**
     * Открытие модального окна
     * ---
     * обновляем контент
     */
    useEffect(() => {

        if (showModal && !loading) {
            setLoading(true);
        }

    }, [showModal]);
    /**
     * Изменение информации компании
     * ---
     * если водитель не выбран, берем первого из списка
     * если водитель выбран, берем первое авто и прицеп из списка
     *
     * получаем список водителей, авто, прицепов
     */
    useEffect(() => {

        if (companyInfo !== null) {

            const selectedDriverId = (filledData.selectedDriver === null && typeof companyInfo.Drivers !== "undefined" && companyInfo.Drivers.length)
                ? companyInfo.Drivers[0].Id
                : filledData.selectedDriver;


            let trucksList = [];
            let trailsList = [];

            for (const [key, value] of Object.entries(companyInfo.Trucks)) {
                trucksList[value.Id] = value;
            }

            for (const [key, value] of Object.entries(companyInfo.Trails)) {
                trailsList[value.Id] = value;
            }

            const selectedDriverInfo = companyInfo.Drivers.filter((itemDriver) => itemDriver.Id === selectedDriverId)[0];

            const newListDriver = (typeof companyInfo.Drivers !== "undefined" && companyInfo.Drivers.length)
                ? companyInfo.Drivers
                : [];


            setListDrivers(newListDriver);
            setListTrucks(trucksList);
            setListTrails(trailsList);

            if (selectedDriverInfo && typeof selectedDriverInfo !== "undefined") {
                setSelectedTruckInfo((typeof selectedDriverInfo !== "undefined" && typeof selectedDriverInfo.Truck !== "undefined" && selectedDriverInfo.Truck !== null)
                    ? selectedDriverInfo.Truck
                    : null);
                setTrailDisabledData(selectedDriverInfo.Truck);
                setFilledData({
                    ...filledData,
                    selectedDriver: (typeof selectedDriverInfo !== "undefined") ? selectedDriverInfo.Id : null,
                    selectedTruck: (typeof selectedDriverInfo !== "undefined" && typeof selectedDriverInfo.Truck !== "undefined" && selectedDriverInfo.Truck !== null)
                        ? selectedDriverInfo.Truck.Id
                        : null,
                    selectedTrail: (typeof selectedDriverInfo !== "undefined" && typeof selectedDriverInfo.Trail !== "undefined" && selectedDriverInfo.Trail !== null)
                        ? selectedDriverInfo.Trail.Id
                        : null,
                });

                if (selectedDriverInfo.Truck) {
                    setListTrucks([...trucksList.filter(item => item.Id !== selectedDriverInfo.Truck.Id), selectedDriverInfo.Truck]);
                }
                if (selectedDriverInfo.Trail) {
                    setListTrails([...trailsList.filter(item => item.Id !== selectedDriverInfo.Trail.Id), selectedDriverInfo.Trail]);
                }
            }
        }

    }, [companyInfo]);

    useEffect(() => {
        if (!loading) {
            setButtonsModal([
                <Button htmlType="button" key="next" type="primary"
                        className={(loading) ? 'disabled' : null}
                        onClick={() => validateOrderData()}
                        style={bigButtonStyle}
                >
                    Далее <Icon style={{ position: "absolute", paddingLeft: "39px", paddingTop: "3px" }} type="right" />
                </Button>
            ]);
        }

        if (loading) {
            if (isForeignDelivery && !foreignPartnersLoad) {
                deliveriesService
                    .getForeignPartnersList()
                    .then((response) => {
                        setForeignPartners(response);
                        setForeignPartnersLoad(true);
                        if (response.length)
                            setFilledData({ ...filledData, selectedForeignPartner: response[(response.length - 1)].Id });
                    })
                    .catch((error) => {
                        setForeignPartners({});
                        setForeignPartnersLoad(true);
                        notification.error({
                            message: "Получение данных заграничных партнёров",
                            description: error,
                        });
                    });
            }

            carrierService
                .getCompany(companyId)
                .then((companyInfo) => {
                    setLoading(false);
                    setCompanyInfo(companyInfo);
                })
                .catch((error) => {
                    setLoading(false);
                    notification.error({
                        message: "Получение данных компании",
                        description: error,
                    });
                });
        }
    }, [loading, filledData]);

    let buttonStyle = { marginTop: "32.5px", marginBottom: '24px', fontSize: "20px", /* width: '40px', */ height: '40px', backgroundColor: '#1CAA53', border: 'none', borderRadius: '0px 4px 4px 0px' };
    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };

    /**
     * Изменение водителя
     * ---
     */
    useEffect(() => {
        if (filledData.selectedDriver !== null) {

            const selectedDriverInfo = listDrivers.filter((itemDriver) => itemDriver.Id === filledData.selectedDriver)[0];

            let newSelectedTruck = null;
            let newSelectedTrail = null;

            if (typeof selectedDriverInfo !== "undefined") {
                const isTrailAttached = typeof selectedDriverInfo.Trail !== "undefined" && selectedDriverInfo.Trail !== null;
                if (typeof selectedDriverInfo.Truck !== "undefined" && selectedDriverInfo.Truck !== null) {
                    newSelectedTruck = selectedDriverInfo.Truck.Id;
                    setListTrucks([...listTrucks.filter(item => item.Id !== selectedDriverInfo.Truck.Id), selectedDriverInfo.Truck]);
                    setTrailDisabledData(selectedDriverInfo.Truck, isTrailAttached);
                }
                if (isTrailAttached) {
                    newSelectedTrail = selectedDriverInfo.Trail.Id;
                    setListTrails([...listTrails.filter(item => item.Id !== selectedDriverInfo.Trail.Id), selectedDriverInfo.Trail]);
                }
            }

            setFilledData({ ...filledData, selectedTruck: newSelectedTruck, selectedTrail: newSelectedTrail });
        }

    }, [filledData.selectedDriver]);

    useEffect(() => {
        if (filledData.selectedTruck) {
            const selectedTruckInfo = listTrucks.filter((itemTruck) => itemTruck.Id === filledData.selectedTruck)[0];
            setTrailDisabledData(selectedTruckInfo);
        }
    }, [filledData.selectedTruck])

    useEffect(() => {

    }, [])


    const setTrailDisabledData = (truckInfo, isHaveTrail = null) => {
        if (truckInfo && truckInfo !== "undefined" && truckInfo !== null) {
            if (truckInfo.carrying !== null && truckInfo.carrying !== undefined) {
                if (truckInfo.carrying >= 20) {
                    setIsNeedTrail(true);
                    setTrailDisabled(false);
                } else {
                    setIsNeedTrail(false);
                    if (isHaveTrail !== null) {
                        setTrailDisabled(!isHaveTrail);
                    }
                }
            } else {
                setTrailDisabled(true);
            }
        }
    };

    const getFieldErrors = (nameField) => {
        return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField]
            : null;
    };

    const validateOrderData = () => {
        let errors = {};

        if (filledData.selectedDriver === null) {
            errors = {
                ...errors,
                driver: "Необходимо выбрать водителя"
            };
        }
        if (filledData.selectedTruck === null) {
            errors = {
                ...errors,
                truck: "Необходимо выбрать авто"
            };
        }
        if (filledData.selectedTrail === null && !trailDisabled) {
            errors = {
                ...errors,
                trail: "Необходимо выбрать прицеп"
            };
        } else {
            filledData.isTrailDisabled = trailDisabled;
        }
        if (filledData.withoutProcuration === false && !filledData.dateProcuration) {
            errors = {
                ...errors,
                dateProcuration: "Укажите дату"
            };
        }
        if (filledData.withoutProcuration === false && !filledData.numberProcuration) {
            errors = {
                ...errors,
                numberProcuration: "Укажите номер"
            };
        }

        if (isForeignDelivery && (filledData.selectedForeignPartner === null || !filledData.selectedForeignPartner)) {
            errors = {
                ...errors,
                foreignPartner: "Укажите заграничного партнёра"
            };
        }

        if (deliveryContractType === DELIVERY_CONTAINER_TYPE) {
            if (!filledData.containerNumber) {
                errors = {
                    ...errors,
                    containerNumber: "Укажите номер контейнера"
                };
            }
        }

        if (Object.keys(errors).length !== 0) {
            setFieldsErrors(errors);
        } else {
            setModalStep(2);
        }
    };



    const findTrucks = (name) => {
        truckService.getListByName(name)
            .then((resp) => {
                setListTrucks(resp);
            })
            .catch((err) => {

            })
    };

    const findTrails = (name) => {
        trailService.getListByName(name)
            .then((resp) => {
                setListTrails(resp);
            })
            .catch((err) => {

            });
    };

    const findDrivers = (name) => {
        driverService.getListByName(name)
            .then((resp) => {
                setListDrivers(resp);
            })
            .catch((err) => {

            });
    };

    const deliveryPriceFormat = (deliveryPrice).toLocaleString('ru');

    return (
        <Spin spinning={loading} tip="Загрузка..." size="large">
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <Col lg={12} md={12} sm={12} xs={12} className="label calendar" style={{ marginBottom: '10px' }}>
                    Стоимость рейса:
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                    {deliveryPriceFormat}
                </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <Col lg={12} md={12} sm={12} xs={12} className="label patch" style={{ marginBottom: '10px' }}>
                    Дата поставки:
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                    {deliveryDateShipmentRus}
                </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <Col lg={12} md={12} sm={12} xs={12} className="label calendar" style={{ marginBottom: '10px' }}>
                    Регион загрузки:
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                    {deliveryRegionLoad}
                </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <Col lg={12} md={12} sm={12} xs={12} className="label dispatch" style={{ marginBottom: '10px' }}>
                    Регион разгрузки:
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                    {deliveryRegionUnload}
                </Col>
            </Row>

            <Form style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)", marginTop: "5px", paddingBottom: "25px" }}>
                <Row gutter={[16, 24]}>
                    <Col style={{ paddingRight: "0px" }} span={10}>
                        <SelectField
                            title="Водитель"
                            name="driver"
                            selectedValue={filledData.selectedDriver}
                            values={listDrivers}
                            onChange={(value) => {
                                setFilledData({ ...filledData, selectedDriver: value });
                            }}
                            onSearch={(value) => {
                                if (value.length >= 2) {
                                    findDrivers(value);
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите водителя"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                            className={"left-border-radius"}
                        />
                    </Col>
                    <Col style={{ paddingLeft: "0px" }} span={1}>
                        <Button type="primary"
                            size="default"
                            htmlType="button"
                            icon="plus"
                            title="Создать водителя"
                            style={buttonStyle}
                            onClick={() => {
                                setIsShowModal(false);
                                setShowModalCreateDriver(true);
                            }}
                        />
                        <ModalCreateDriver
                            companyId={companyId}
                            isShow={showModalCreateDriver}
                            setIsShow={setShowModalCreateDriver}
                            setShowAttachDriverOrderDelivery={setIsShowModal}
                            setIsNeedUpdateList={setIsNeedUpdateList}
                        />
                    </Col>

                    <Col style={{ paddingRight: "0px" }} span={10} offset={2}>
                        <SelectField
                            title="Автомобиль"
                            name="truck"
                            selectedValue={filledData.selectedTruck}
                            values={listTrucks}
                            onChange={(value) => {
                                setFilledData({ ...filledData, selectedTruck: value });
                            }}
                            onSearch={(value) => {
                                if (value.length >= 2) {
                                    findTrucks(value);
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите авто"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                            className={"left-border-radius"}
                        />
                    </Col>
                    <Col style={{ paddingLeft: "0px" }} span={1}>
                        <Button type="primary"
                            size="default"
                            htmlType="button"
                            icon="plus"
                            title="Создать автомобиль"
                            style={buttonStyle}
                            onClick={() => {
                                setIsShowModal(false);
                                setShowModalCreateTruck(true);
                            }}
                        />
                        <ModalCreateTruck
                            companyId={companyId}
                            driverId={filledData.selectedDriver}
                            isShow={showModalCreateTruck}
                            setIsShow={setShowModalCreateTruck}
                            setShowAttachDriverOrderDelivery={setIsShowModal}
                            carryingCapacityValues={carryingCapacityValues}
                            setIsNeedUpdateList={setIsNeedUpdateList}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]} >
                    {isNeedTrail ? null : (
                        <React.Fragment>
                            <Checkbox
                                name="isTrailDisabled"
                                checked={trailDisabled}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    setTrailDisabled(checked);
                                    if (checked) {
                                        setFilledData({ ...filledData, selectedTrail: null });
                                    }
                                }}
                                style={{ paddingLeft: "8px", paddingBottom: "15px" }}
                            >
                                Без прицепа
                            </Checkbox>
                        </React.Fragment>
                    )}
                </Row>
                <Row gutter={[16, 24]} >
                    <Col style={{ paddingRight: "0px" }} span={10}>
                        <SelectField
                            title="Прицеп"
                            name="trail"
                            isDisabled={trailDisabled}
                            selectedValue={filledData.selectedTrail}
                            values={listTrails}
                            onChange={(value) => {
                                setFilledData({ ...filledData, selectedTrail: value });
                            }}
                            onSearch={(value) => {
                                if (value.length >= 2) {
                                    findTrails(value);
                                }
                            }}
                            getErrors={getFieldErrors}
                            placeholder="Укажите прицеп"
                            notFoundText={null}
                            showArrow={false}
                            style={{ width: "100%" }}
                            className={"left-border-radius"}
                        />
                    </Col>
                    <Col style={{ paddingLeft: "0px" }} span={1} >
                        <Button
                            type="primary"
                            size="default"
                            htmlType="button"
                            icon="plus"
                            title="Создать прицеп"
                            style={buttonStyle}
                            onClick={() => {
                                setIsShowModal(false);
                                setShowModalCreateTrail(true);
                            }}
                        />
                        <ModalCreateTrail
                            companyId={companyId}
                            driverId={filledData.selectedDriver}
                            isShow={showModalCreateTrail}
                            setIsShow={setShowModalCreateTrail}
                            setShowAttachDriverOrderDelivery={setIsShowModal}
                            truck={selectedTruckInfo}
                            carryingCapacityValues={carryingCapacityValues}
                            setIsNeedUpdateList={setIsNeedUpdateList}
                        />
                    </Col>
                    <Col lg={2} md={0} sm={0} xs={0}></Col>

                    <Col lg={5} md={24} sm={24} xs={24}>
                        <FieldBlock name="dateProcuration" title="Дата доверености" getErrors={getFieldErrors}>
                            <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={filledData.dateProcuration}
                                format="DD.MM.YYYY"
                                size="large"
                                name="dateProcuration"
                                locale={localeDatePicker}
                                allowClear={false}
                                onChange={(date) => {
                                    setFilledData({
                                        ...filledData,
                                        dateProcuration: date
                                    });
                                }}
                            />
                        </FieldBlock>
                        <span className="checkbox-label">Без доверености</span>
                        <Checkbox
                            name="withoutProcuration"
                            checked={filledData.withoutProcuration}
                            onChange={(e) => {
                                setFilledData({
                                    ...filledData,
                                    withoutProcuration: e.target.checked
                                });
                            }}
                            style={{ paddingTop: "15px" }}
                        >
                        </Checkbox>
                    </Col>
                    <Col lg={1} md={24} sm={24} xs={24}></Col>
                    <Col lg={5} md={24} sm={24} xs={24}>
                        <InputField name="numberProcuration" label="Номер доверености"
                            value={filledData.numberProcuration}
                            setValue={(value) => {
                                setFilledData({
                                    ...filledData,
                                    numberProcuration: value
                                });
                            }}
                            getFieldErrors={getFieldErrors}
                        />
                    </Col>
                </Row>
                {(isForeignDelivery) ? (
                    <Row gutter={[16, 24]} >
                        <Col lg={23} md={23} sm={23} xs={23} style={{ paddingRight: "0px" }}>
                            <SelectField
                                className="select-wo-right-borders"
                                title="Второй перевозчик"
                                name="foreignPartner"
                                selectedValue={filledData.selectedForeignPartner}
                                values={foreignPartners}
                                onChange={(value) => {
                                    setFilledData(
                                        { ...filledData, selectedForeignPartner: value }
                                    );
                                }}
                                getErrors={getFieldErrors}
                                notFoundText="Второй перевозчик отсутствует"
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1} style={{ paddingLeft: "0px" }}>
                            <Button type="primary"
                                size="default"
                                htmlType="button"
                                icon="plus"
                                title="Создать заграничного партнёра"
                                style={buttonStyle}
                                onClick={() => {
                                    setIsShowModal(false);
                                    setShowModalCreateForeignPartner(true);
                                }}
                            />
                            <ModalCreateForeignPartner
                                isShow={showModalCreateForeignPartner}
                                setIsShow={setShowModalCreateForeignPartner}
                                setShowAttachDriverOrderDelivery={setIsShowModal}
                                setUpdateForeignPartnerList={setForeignPartnersLoad}
                            />
                        </Col>
                    </Row>
                ) : (
                    null
                )}
                {(deliveryContractType === DELIVERY_CONTAINER_TYPE) &&
                    <Row gutter={[16, 24]}>
                        <Col lg={5} md={24} sm={24} xs={24}>
                            <InputField name="containerNumber" label="Номер контейнера"
                                value={filledData.containerNumber}
                                setValue={(value) => {
                                    setFilledData({
                                        ...filledData,
                                        containerNumber: value
                                    });
                                }}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                    </Row>
                }
            </Form>
        </Spin>
    );
}
