import {httpRequest} from "./http-request";

export const reportService = {
    getStatsOrdersDelivery,
    getStatsCargos,
    getStatsRefusedDeliveries,
    getStatsLogistsResponseToOrders,
    getStatsMobileAppUsers,
    getStatsContracts,
    getStatsLegalEntities,
    getStatsMobileAppInstall,
    getStatsCostKilometer,
    getStatsTelegramSetups,
    getStatsOrdersDeliveryByTime,
    getStatsOrdersDeliveryOverpayment,
    getStatsFreeDeliveries,
    getStatsCargoTimes,
    getStatsDriversLocation,
    getStatsUsersIp,
    getDeliveryLoadChanges,
    getStatsUsersTerms,
};


/**
 * Сформировать отчет по созданным, одобренным, отмененным заявкам пользователей
 */
async function getStatsOrdersDelivery(dateStart, dateEnd, distCenters = null) {

    return await httpRequest.post(`report/stats-orders-delivery`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distCenters: distCenters
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsCargos(dateStart, dateEnd, distCenters = null) {
    return await httpRequest.post(`report/stats-cargos`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distCenters: distCenters
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsRefusedDeliveries(dateStart, dateEnd, distCenters = null) {
    return await httpRequest.post(`report/stats-refused-deliveries`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distCenters: distCenters
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsLogistsResponseToOrders(dateStart, dateEnd, distCenters = null) {
    return await httpRequest.post(`report/stats-logists-response-to-orders`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distCenters: distCenters
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsMobileAppUsers(dateStart, dateEnd) {
    return await httpRequest.post(`report/stats-mobile-app-users`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsContracts(dateStart, dateEnd) {
    return await httpRequest.post(`report/stats-contracts`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsLegalEntities(dateStart, dateEnd) {
    return await httpRequest.post(`report/stats-legal-entities`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsMobileAppInstall(dateStart, dateEnd, distributionCenterId) {
    return await httpRequest.post(`report/stats-mobile-app-install`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distributionCenterId: distributionCenterId
    })
        .then((response) => {

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsCostKilometer(dateStart, dateEnd, distributionCenterId) {
    return await httpRequest.post(`report/stats-cost-kilometer`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distributionCenterId: distributionCenterId
    })
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsTelegramSetups(dateStart, dateEnd, distributionCenterId) {
    return await httpRequest.post(`report/stats-telegram-setups`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distributionCenterId: distributionCenterId
    })
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsOrdersDeliveryByTime(dateStart, dateEnd, distributionCenterId, countryId, intervalMinutesStart, intervalMinutesEnd, onlyAgreedOrders, approvingEmployeeId) {
    return await httpRequest.post(`report/stats-orders-delivery-by-time`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distributionCenterId: distributionCenterId,
        countryId: countryId,
        intervalMinutesStart: intervalMinutesStart,
        intervalMinutesEnd: intervalMinutesEnd,
        onlyAgreedOrders: onlyAgreedOrders ? 1 : 0,
        approvingEmployeeId: approvingEmployeeId,
    })
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsOrdersDeliveryOverpayment(dateStart, dateEnd, distributionCenterId, countryId, approvingEmployeeId, overpaymentFrom, overpaymentTo) {
    return await httpRequest.post(`report/stats-orders-delivery-overpayment`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distributionCenterId: distributionCenterId,
        countryId: countryId,
        approvingEmployeeId: approvingEmployeeId,
        overpaymentFrom: overpaymentFrom,
        overpaymentTo: overpaymentTo,
    })
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsFreeDeliveries(distributionCenterID, shipperCompanyID, isReturn) {
    let data = {
        distributionCenterID: distributionCenterID,
        shipperCompanyID: shipperCompanyID,
    }

    if (isReturn !== null) {
        data.isReturn = isReturn
    }

    return await httpRequest
        .post(`report/stats-free-deliveries`, data)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsCargoTimes(distCenters, dateStart, dateEnd) {
  let data = {
    distCenters: distCenters,
    dateStart: dateStart,
    dateEnd: dateEnd
  }

  return await httpRequest
    .post(`report/stats-cargo-times`, data)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getDeliveryLoadChanges(distCenters, dateStart, dateEnd, deliveryNumber) {
    let data = {
      distCenters: distCenters,
      dateStart: dateStart,
      dateEnd: dateEnd,
      deliveryNumber: deliveryNumber
    }
  
    return await httpRequest
      .post(`report/stats-delivery-load-changes`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }
  
        return Promise.reject(httpRequest.getDefaultMessageError());
      });
  }

async function getStatsDriversLocation(dateStart, dateEnd, distributionsCentersID, deliveryStatus) {
    return await httpRequest.post(`report/stats-drivers-location`, {
        dateStart: dateStart,
        dateEnd: dateEnd,
        distributionsCentersID: distributionsCentersID,
        deliveryStatus: deliveryStatus,
    })
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStatsUsersIp (
    limit, offset,
    minIpCount, maxIpCount,
    minRegionsCount, maxRegionsCount,
    multiRegionMode, mode,
    name
) {
  return await httpRequest
    .post(`report/stats-users-ip`, {
      limit: limit ? limit : 50,
      offset: offset ? offset : 0,
      minIpCount: minIpCount,
      maxIpCount: maxIpCount,
      minRegionsCount: minRegionsCount,
      maxRegionsCount: maxRegionsCount,
      multiRegionMode: multiRegionMode ? multiRegionMode : "all",
      name: name ? name : '',
      mode: mode ? mode : "all",
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getStatsUsersTerms (
    legalNumber, name, dateStart, dateEnd
) {
  return await httpRequest
    .post(`report/stats-users-terms`, {
      legalNumber: legalNumber,
      name: name,
      dateStart: dateStart,
      dateEnd: dateEnd,
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
