import React from "react";
import {useEffect, useState} from "react";
import {carrierService} from "../../../services";
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import SelectField from '../../select-field';
import ModalCreateContractOrder from "../create-contract-order";

export default function ModalSelectCarrierLegalEntity({
                                           isShow,
                                           setIsShow,
                                           delivery,
                                           setNeedUpdateDeliveryList,
                                           setStopListUpdate,
                                           initLegalEntity,
                                         }) {
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [listCompanies, setListCompanies] = useState([]);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showOrderToShipperCompanyModal, setShowOrderToShipperCompanyModal] = useState(false);
  const [legalEntityId, setLegalEntityId] = useState(null);
  const currentShipperCompanyId = delivery.CustomerCompanyId;

  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };

  const textStyle = {
    textAlign: 'center',
    color: 'black'
  };



  useEffect(() => {
    if (isShow) {
      carrierService.getCompanies()
        .then((companies) => {
          setListCompanies(companies.LegalEntities);
        })
        .catch((error) => {
          setLoadingContentModal(false);
          notification.error({
            message: "Получение данных о компаниях",
            description: error,
          });
        });
    }
  }, [isShow]);

  useEffect(() => {

    if (isShow && !loadingContentModal) {
      setLoadingContentModal(true);
      setStopListUpdate(true);
    }
  }, [isShow]);


  useEffect(() => {
    if (listCompanies && listCompanies.length) {
      setLoadingContentModal(false);
    }
  }, [listCompanies]);


  const getFieldErrors = (nameField) => {

    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  if (!loadingContentModal && listCompanies) {
    if (listCompanies.length && legalEntityId === null) {
      setLegalEntityId(initLegalEntity);
    }
  }

  let content;

  if (errorMsg) {
    content = (<div className="content-error-block">{errorMsg}</div>);
  } else {
    content = (
      <Spin spinning={loadingContentModal} tip="Загрузка...">
        <Row className="add-padding-10 -centered">
            <h2 style={textStyle}>Для подачи заявки на рейс Вам необходимо заключить договор c грузоотправителем <span>{delivery.CustomerCompany}</span></h2>
        </Row>
        <Row gutter={[24, 26]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <SelectField
              title="Компания"
              name="companyId"
              selectedValue={legalEntityId}
              values={listCompanies}
              onChange={(value) => {
                setLegalEntityId(value);
              }}
              getErrors={getFieldErrors}
              notFoundText="Отсутствуют юр.лица"
              style={{width: "100%"}}
            />
          </Col>
        </Row>
      </Spin>
    );
  }

  return (
    <>
    <Modal
      title="Заключение договора"
      okText="Подать заявку"
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        setNeedUpdateDeliveryList(true);
        setStopListUpdate(false);
      }}
      footer={[
        (errorMsg === null)
          ? (<Button htmlType="button" disabled={loadingContentModal} style={bigButtonStyle} key="submit" type="primary"
              onClick={setShowOrderToShipperCompanyModal}>Отправить</Button>)
          : null,
      ]}
    >
      {content}
    </Modal>
    <ModalCreateContractOrder shipperCompanyID={currentShipperCompanyId} legalEntityID={legalEntityId} isShow={showOrderToShipperCompanyModal} setIsShow={setShowOrderToShipperCompanyModal} />
    </>
  );
}
