import React, {useState, useEffect, useContext, useRef} from 'react';
import {Layout, Menu, Icon, notification, Button} from 'antd';
import {Link, Route, Redirect} from 'react-router-dom';

import {AppContext} from "../../../contexts/app-context";
import {consignorService, userServices} from "../../../services";
import Error from "../../error";
import ReportStatsOrdersDelivery from './stats-orders-delivery';
import ReportStatsCargo from './stats-cargo';
import {useFetchDeliveriesList} from '../../../hooks/use-fetch-deliveries-list';
import Spinner from '../../../components/spinner';
import NotificationMessage from '../../../components/modals/notification-message';

import {VIEW_REPORT_STATS_ORDER_DELIVERIES} from "../../../helpers/user_permission.helper";
import ReportStatsRefusedDeliveries from "./stats-refused-deliveries";
import ReportStatsLogistsResponseToOrders from "./stats-logists-response-to-orders";
import ReportStatsMobileAppUsers from "./stats-mobile-app-users";
import ReportStatsContracts from "./stats-contracts";
import ReportStatsLegalEntities from "./stats-legal-entities";
import ReportStatsMobileAppInstall from "./stats-mobile-app-install";
import ReportStatsCostKilometer from "./stats-cost-kilometer";
import ReportStatsTelegramSetups from "./stats-telegram-setups";
import ReportStatsOrdersDeliveryByTime from "./stats-orders-delivery-by-time";
import ReportStatsOrdersDeliveryOverpayment from "./stats-orders-delivery-overpayment";
import StatsFreeDeliveries from "./stats-free-deliveries";
import ReportStatsDriversLocation from "./stats-drivers-location";
import ReportStatsUsersTerms from './stats-users-terms';
import ReportStatsIP from "./stats-ip";
import StatsCargoTimes from "./stats-cargo-times";
import StatsDeliveryLoadChanges from './stats-delivery-load-changes';

const menuElements = [
  {
    parentKey: 'group1',
    link: "/reports/stats-orders-delivery",
    label: <span><Icon type="pie-chart"/><span>Статистика по заявкам</span></span>,
    subMenu: false,
    accessType: 'stats_orders_delivery_access',
    items: []
  },
  {
    parentKey: 'group2',
    link: "/reports/stats-cargo",
    label: <span><Icon type="bar-chart"/><span>Статистика по грузам</span></span>,
    subMenu: false,
    accessType: 'stats_cargo_access',
    items: []
  },
  {
    parentKey: 'group3',
    link: "/reports/stats-refused-deliveries",
    label: <span><Icon type="fall"/><span>Статистика по отменённым доставкам</span></span>,
    subMenu: false,
    items: []
  },
  {
    parentKey: 'group4',
    link: "/reports/stats-logists-response-to-orders",
    label: <span><Icon type="dashboard"/><span>Статистика по времени отклика логистов на заявки</span></span>,
    subMenu: false,
    accessType: 'stats_logists_respond_to_order_access',
    items: []
  },
  {
    parentKey: 'group5',
    link: "/reports/stats-mobile-app-users",
    label: <span><Icon type="mobile"/><span>Статистика использования мобильного приложения</span></span>,
    subMenu: false,
    accessType: 'stats_mobile_app_users_access',
    items: []
  },
  {
    parentKey: 'group6',
    link: "/reports/stats-contracts",
    label: <span><Icon
      type="file-done"/><span>Статистика по перевозчикам с договорами заключенными через сайт</span></span>,
    subMenu: false,
    accessType: 'stats_site_contract_access',
    items: []
  },
  {
    parentKey: 'group7',
    link: "/reports/stats-legal-entities",
    label: <span><Icon type="idcard"/><span>Статистика по зарегистрированным юридическим лицам</span></span>,
    subMenu: false,
    accessType: 'stats_legal_entities_access',
    items: []
  },
  {
    parentKey: 'group8',
    link: "/reports/stats-mobile-app-install",
    label: <span><Icon type="mobile"/><span>Статистика по установке мобильного приложения</span></span>,
    subMenu: false,
    accessType: 'stats_mobile_app_install_access',
    items: []
  },
  {
    parentKey: 'group9',
    link: "/reports/stats-cost-kilometer",
    label: <span><Icon type="pound"/><span>Статистика по стоимости километра</span></span>,
    subMenu: false,
    accessType: 'stats_kilometer_cost_access',
    items: []
  },
  {
    parentKey: 'group10',
    link: "/reports/stats-telegram-setups",
    label: <span><Icon type="mobile"/><span>Статистика по установкам Телеграм</span></span>,
    subMenu: false,
    accessType: 'stats_telegram_setups_access',
    items: []
  },
  {
    parentKey: 'group11',
    link: "/reports/stats-orders-delivery-by-time",
    label: <span><Icon type="pie-chart"/><span>Статистика по времени создания заявки</span></span>,
    subMenu: false,
    accessType: 'stats_orders_delivery_by_legal_entity_access',
    items: []
  },
  {
    parentKey: 'group12',
    link: "/reports/stats-orders-delivery-overpayment",
    label: <span><Icon type="dollar"/><span>Статистика по переплатам</span></span>,
    subMenu: false,
    accessType: 'stats_orders_delivery_overpayment_access',
    items: []
  },
  {
    parentKey: 'group13',
    link: "/reports/stats-free-deliveries",
    label: <span><Icon type="rocket"/><span>Отчет «Свободные рейсы»</span></span>,
    subMenu: false,
    accessType: 'stats_free_deliveries_access',
    items: []
  },
  {
    parentKey: 'group14',
    link: "/reports/stats-drivers-location",
    label: <span><Icon type="arrow-right"/><span>Отчет «Геолокация водителей»</span></span>,
    subMenu: false,
    accessType: 'stats_drivers_location',
    items: []
  },
  {
    parentKey: 'group15',
    link: "/reports/stats-ip",
    label: <span><Icon type="pie-chart"/><span>Статистика IP</span></span>,
    subMenu: false,
    accessType: 'stats_orders_delivery_access',
    items: []
  },
  {
    parentKey: 'group16',
    link: "/reports/stats-cargo-times",
    label: <span><Icon type="pie-chart"/><span>Отчёт по временным меткам рейса</span></span>,
    subMenu: false,
    accessType: 'stats_orders_delivery_access',
    items: []
  },
  {
    parentKey: 'group17',
    link: "/reports/users-terms",
    label: <span><Icon type="file-done"/><span>Отчёт по пользовательским соглашениям</span></span>,
    subMenu: false,
    accessType: 'stats_users_terms',
    items: []
  },
  {
    parentKey: 'group18',
    link: "/reports/stats-delivery-load-changes",
    label: <span><Icon type="calendar"/><span>Отчёт по переносам даты загрузки рейса</span></span>,
    subMenu: false,
    accessType: 'stats_orders_delivery_access',
  }
];

function LeftMenu({collapsed, menuElementsData, consignorData}) {
  const menuElements = menuElementsData.map((menuItemData) => {
    if (menuItemData.accessType && consignorData && !consignorData.isVital) {
      if (!consignorData.premiumAccessList[menuItemData.accessType]) {
        return null;
      }
    }

    if (menuItemData.subMenu) {
      const groupItems = menuItemData.items.map((item) => {
        return (<Menu.Item key={item.key}><Link to={item.link}>{item.label}</Link></Menu.Item>);
      });

      return (
        <Menu.SubMenu key={menuItemData.parentKey} title={menuItemData.label}>
          {groupItems}
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item key={menuItemData.parentKey}><Link to={menuItemData.link}>{menuItemData.label}</Link></Menu.Item>);
    }
  });

  const menuSelectedItem = menuElementsData
    .filter((data) => {
      if (data.subMenu) {
        const itemsLinks = data.items.filter((dataItem) => {
          return (dataItem.link === window.location.pathname);
        });

        return (itemsLinks.length);
      } else {
        return (data.link === window.location.pathname);
      }

    })
    .map((item) => {

      if (item.subMenu) {

        const itemsLinks = item.items.filter((dataItem) => {
          return (dataItem.link === window.location.pathname);
        });

        return {parentKey: item.parentKey, itemKey: itemsLinks[0].key};
      } else {
        return {parentKey: item.parentKey, itemKey: item.parentKey};
      }
    })
    .shift();

  return (
    <Menu
      defaultSelectedKeys={(typeof menuSelectedItem !== "undefined") ? [menuSelectedItem.itemKey] : []}
      defaultOpenKeys={(typeof menuSelectedItem !== "undefined") ? [menuSelectedItem.parentKey] : []}
      mode="inline"
      theme="light"
    >
      {menuElements}
    </Menu>
  );
}

export default function ReportsPage() {
  const [collapsed, setCollapsed] = useState(false);
  const appContext = useContext(AppContext);
  const [consignorInfo, setConsignorInfo] = useState(null);

  useEffect(() => {
    consignorService.getInfo()
      .then(resp => {
        setConsignorInfo(resp);
      })
      .catch(err => {
        notification.error({
          message: "Получение информации о грузоотправителе",
          description: err,
        });
      })
  }, [])

  const handleResize = () => {
    if (collapsed === false && window.innerWidth < 1000) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const [sectionHeight, setSectionHeight] = useState(window.innerHeight);
  const [menuFixedPosition, setMenuFixedPosition] = useState(0)

  useEffect(() => {
    const header = document.querySelector('.header');
    const headerHeight = header.offsetHeight + parseInt(getComputedStyle(header, true).marginBottom);
    setSectionHeight(window.innerHeight - headerHeight);
    setMenuFixedPosition(headerHeight - 28);
  })

  handleResize();

  if (appContext.filter.enable) {
    appContext.disableFilter();
  }

  const user = userServices.getUser();

  const [{
    error, loading, setNeedUpdate, setStopListUpdate, notifications
  }] = useFetchDeliveriesList('', true, false, false, true);

  if (!user.isAccessTo(VIEW_REPORT_STATS_ORDER_DELIVERIES)) {
    return (<Error status={403} desc="Страница не доступна"/>);
  }

  if (window.location.pathname === '/reports') {
    return (<Redirect to="/reports/stats-refused-deliveries"/>);
  }

  if (error) {
    return (<Error type={500} desc={error.message}/>);
  }
  if (loading) {
    return (
      <div className="reports">
        <Spinner loading={loading}/>
      </div>
    );
  }

  return (
    <Layout tagName="section" className="reports" style={{minHeight: sectionHeight}}>
      <NotificationMessage notifications={notifications} setNeedUpdate={setNeedUpdate}
                           setStopListUpdate={setStopListUpdate}/>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
        width={293}
        className="reports__menu-list"
        style={{background: "unset", borderRight: "none"}}
      >
        <div className="reports__menu-wrapper" style={{top: menuFixedPosition, maxHeight: sectionHeight}}>
          <div className="reports__trigger"
               onClick={() => {
                 setCollapsed(!collapsed)
               }}>
            <Icon
              className="trigger"
              type={collapsed ? 'menu-unfold' : 'menu-fold'}
            />
          </div>
          <LeftMenu collapsed={collapsed}
                    menuElementsData={menuElements} consignorData={consignorInfo}/>
        </div>
      </Layout.Sider>
      <Layout.Content className="reports__wrapper table-window">
        <Route path="/reports/stats-orders-delivery" exact component={ReportStatsOrdersDelivery}/>
        <Route path="/reports/stats-cargo" exact component={ReportStatsCargo}/>
        <Route path="/reports/stats-refused-deliveries" exact component={ReportStatsRefusedDeliveries}/>
        <Route path="/reports/stats-logists-response-to-orders" exact component={ReportStatsLogistsResponseToOrders}/>
        <Route path="/reports/stats-mobile-app-users" exact component={ReportStatsMobileAppUsers}/>
        <Route path="/reports/stats-contracts" exact component={ReportStatsContracts}/>
        <Route path="/reports/stats-legal-entities" exact component={ReportStatsLegalEntities}/>
        <Route path="/reports/stats-mobile-app-install" exact component={ReportStatsMobileAppInstall}/>
        <Route path="/reports/stats-cost-kilometer" exact component={ReportStatsCostKilometer}/>
        <Route path="/reports/stats-telegram-setups" exact component={ReportStatsTelegramSetups}/>
        <Route path="/reports/stats-orders-delivery-by-time" exact component={ReportStatsOrdersDeliveryByTime}/>
        <Route path="/reports/stats-orders-delivery-overpayment" exact
               component={ReportStatsOrdersDeliveryOverpayment}/>
        <Route path="/reports/stats-free-deliveries" exact component={StatsFreeDeliveries}/>
        <Route path="/reports/stats-drivers-location" exact component={ReportStatsDriversLocation}/>
        <Route path="/reports/stats-ip" exact component={ReportStatsIP}/>
        <Route path="/reports/stats-cargo-times" exact component={StatsCargoTimes}/>
        <Route path="/reports/stats-delivery-load-changes" exact component={StatsDeliveryLoadChanges}/>
        <Route path="/reports/users-terms" exact component={ReportStatsUsersTerms} />
      </Layout.Content>
    </Layout>
  );
}
